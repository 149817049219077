import { createContext } from "react";

export const ClientIDs = {
  androidClientId:
    "126626201768-qfrv2m4ni1bb0pdka3e969i3mt65bej1.apps.googleusercontent.com",
  iosClientId:
    "126626201768-ro0eff0qrmsnvhj42gg1ujbaku5h4dok.apps.googleusercontent.com",
  webClientId:
    "126626201768-vpepsjvknf96ukie4612ptelqrlqbos3.apps.googleusercontent.com",
};

export const UserContext = createContext();
