import { View, Text, StyleSheet, ActivityIndicator } from "react-native";
// THEMEING
import { ThemeContext } from "../contexts/theme-context";
import { UserContext } from "../contexts/user-context";
import { useContext, useEffect, useState } from "react";
import { getUserStats } from "../api/user";
import { RouteContext } from "../contexts/route-context";

function ProfileHeaderStats({ userID, postRemoved, setPostRemoved }) {
  const theme = useContext(ThemeContext);
  const { feedRerender, setFeedRerender } = useContext(RouteContext);

  const [stats, setStats] = useState({ sessionCNT: null, checkCNT: null });

  useEffect(() => {
    const fetchStats = async () => {
      const response = await getUserStats({
        queryTerms: { userID: userID },
      });
      setStats({
        sessionCNT: response.sessionCNT,
        checkCNT: response.checkCNT,
      });
    };
    if (userID) {
      fetchStats();
    }

    if (feedRerender) {
      setFeedRerender(false);
    }
    if (postRemoved) {
      setPostRemoved(false);
    }
  }, [userID, feedRerender, postRemoved]);

  return (
    <View style={styles.stats}>
      {userID ? (
        <>
          <View style={[styles.stat_text]}>
            {stats.sessionCNT || stats.sessionCNT === 0 ? (
              <>
                <Text style={{ color: theme.typography.primary }}>
                  {stats.sessionCNT}
                </Text>
                <Text style={{ color: theme.typography.primary }}>
                  Sessions
                </Text>
              </>
            ) : (
              <ActivityIndicator
                size="small"
                color={theme.typography.primary}
              />
            )}
          </View>
          <View style={[styles.divider, { borderColor: theme.divider }]} />
          <View style={[styles.stat_text]}>
            {stats.checkCNT || stats.checkCNT === 0 ? (
              <>
                <Text style={{ color: theme.typography.primary }}>
                  {stats.checkCNT}
                </Text>
                <Text style={{ color: theme.typography.primary }}>
                  Surf Checks
                </Text>
              </>
            ) : (
              <ActivityIndicator
                size="small"
                color={theme.typography.primary}
              />
            )}
          </View>
        </>
      ) : (
        <Text
          style={{
            color: theme.typography.primary,
            width: "50%",
            textAlign: "center",
          }}
        >
          Create an account to keep track of your surf stats!
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  stats: {
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
  },
  stat_text: {
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    borderBottomWidth: 2,
    width: 40,
    margin: 8,
    alignItems: "center",
    flexDirection: "column",
  },
});

export default ProfileHeaderStats;
