import { StyleSheet, View, Text, FlatList } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext, useMemo } from "react";
import HourlyForecastOverview from "./HourlyForecastOverview";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../contexts/weather-context";
import { reverseCompassDirection } from "../../utilFunctions/directionConversionFunctions";
import { shortenUnit } from "../../utilFunctions/unitConversionFunctions";
import moment from "moment";

function HourlyWeatherContainer() {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const chart = useContext(WeatherChartContext);

  const spotWeather = weather.weatherForecasts[chart.spotID];

  const currentHourIndex = weather.timeArray[0].indexOf(weather.currentHour);
  const forecastHourArray = useMemo(() => {
    return [
      ...weather.timeArray[0].slice(currentHourIndex),
      ...weather.timeArray[1].slice(0, currentHourIndex),
    ];
  }, [weather.currentHour]);

  return (
    <View style={[styles.contentContainer, { borderColor: theme.divider }]}>
      <View style={[styles.titleContainer, { borderColor: theme.divider }]}>
        <Text style={[styles.titleText, { color: theme.typography.primary }]}>
          Hourly Forecast
        </Text>
      </View>
      <View style={[styles.forecastContainer]}>
        <FlatList
          data={forecastHourArray}
          renderItem={({ item, index }) => (
            <HourlyForecastOverview
              data={{
                time: {
                  time: index ? moment(item).format("h") : "Now",
                  label: index ? moment(item).format("A") : "",
                },
                wave: {
                  height: spotWeather.get("swellHeight").forecast[item],
                  direction: reverseCompassDirection(
                    spotWeather.get("swellDirection").forecast[item]
                  ),
                  period: {
                    value: spotWeather.get("swellPeriod").forecast[item],
                    units: "s",
                  },
                },
                wind: {
                  sustained: {
                    value: spotWeather.get("windSustained").forecast[item],
                    units: shortenUnit(spotWeather.get("windSustained").units),
                  },
                  direction: reverseCompassDirection(
                    parseInt(spotWeather.get("windDirection").forecast[item])
                  ),
                },
              }}
            />
          )}
          keyExtractor={(item) => item}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    width: "90%",
    borderWidth: 1,
    borderRadius: 10,
    marginVertical: 20,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  titleText: { fontSize: 16, paddingTop: 10, paddingBottom: 10 },
  forecastContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 170,
  },
});

export default HourlyWeatherContainer;
