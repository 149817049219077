import { View, Text, StyleSheet, Dimensions } from "react-native";
const dimensions = Dimensions.get("window");
// THEMEING
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";
import Avatar from "../uiCommon/Avatar";

const ProfileAvatarUsername = (props) => {
  const { size, user, align } = props;

  const theme = useContext(ThemeContext);

  return (
    <View
      style={[
        styles.user_details,
        { alignItems: typeof align === "undefined" ? "center" : align },
      ]}
    >
      <Avatar user={user} size={size * (7 / 10)} />
      <Text
        style={[
          styles.user_name,
          {
            color: theme.typography.primary,
            fontSize: size / 5,
          },
        ]}
      >
        {user}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  user_details: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
  },
  user_name: {},
});

export default ProfileAvatarUsername;
