import React, { useEffect, useContext, useState, useMemo } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";

import WeatherOverview from "./WeatherOverview";
import SpotDetails from "./weatherDetails/SpotDetails";
import WeatherSpotSearch from "./locationSearch/WeatherSpotSearch";
import { getForecast } from "../api/forecast";
import { UserContext } from "../contexts/user-context";
import { WeatherContext } from "../contexts/weather-context";

const Stack = createNativeStackNavigator();

function Weather() {
  // State and context variables
  const [weatherSpots, setWeatherSpots] = useState([]);
  const [weatherForecasts, setWeatherForecasts] = useState({});
  const { user } = useContext(UserContext);

  // Calculate current moment and current hour
  const currentMoment = moment();
  const currentHour = useMemo(
    () => currentMoment.utc().format("YYYY-MM-DDTHH:00:00[Z]"),
    [currentMoment]
  );

  // Constants for data processing
  const totalEntries = 144;
  const numArrays = 6;
  const entriesPerArray = totalEntries / numArrays;

  // Create an array of time intervals
  const timeArray = useMemo(() => {
    return Array.from({ length: numArrays }, (_, arrayIdx) =>
      new Array(entriesPerArray).fill(0).map((_, idx) =>
        moment()
          .startOf("day")
          .add(arrayIdx * entriesPerArray + idx, "h")
          .utc()
          .format("YYYY-MM-DDTHH:00:00[Z]")
      )
    );
  }, []);

  // Fetch favorite weather spots from user or local storage
  useEffect(() => {
    const getLocalStoreFavSpots = async () => {
      try {
        const storedSpots = JSON.parse(
          await AsyncStorage.getItem("@favoriteSpots")
        );
        if (storedSpots) {
          setWeatherSpots(storedSpots);
        }
      } catch (error) {
        console.error("Error retrieving favorite spots:", error);
      }
    };

    if (user) {
      const favoriteSpots = user.settings.favoriteSpots;
      if (favoriteSpots.length) {
        setWeatherSpots(favoriteSpots);
      }
    } else {
      getLocalStoreFavSpots();
    }
  }, [user]);

  // Fetch weather data for favorite spots when weatherSpots or currentHour changes
  useEffect(() => {
    const fetchSpotWeather = async (spotID, startTime, endTime) => {
      const requestData = {
        queryTerms: {
          spotID: spotID,
        },
        projectionFields: {
          range: true,
          startTime: startTime,
          endTime: endTime,
        },
      };

      try {
        const response = await getForecast(requestData);
        setWeatherForecasts((prevForecasts) => ({
          ...prevForecasts,
          [spotID]: new Map(response.map((obj) => [obj.type, obj])),
        }));
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    if (weatherSpots.length) {
      const dataRequests = weatherSpots.map((spotID) => {
        if (!weatherForecasts[spotID]) {
          return fetchSpotWeather(
            spotID,
            timeArray[0][0],
            timeArray[numArrays - 1][entriesPerArray - 1]
          );
        }
        return null;
      });

      Promise.all(dataRequests).catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [weatherSpots, currentHour]);

  return (
    <WeatherContext.Provider
      value={{
        weatherSpots,
        setWeatherSpots,
        weatherForecasts,
        timeArray,
        currentHour,
      }}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Weather Overview" component={WeatherOverview} />
        <Stack.Screen name="SpotDetails" component={SpotDetails} />
        <Stack.Screen name="Location Search" component={WeatherSpotSearch} />
      </Stack.Navigator>
    </WeatherContext.Provider>
  );
}

export default Weather;
