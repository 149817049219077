import { TouchableHighlight, Text, StyleSheet, View } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function MiniButton({ onPress, title, backgroundColor, color }) {
  const theme = useContext(ThemeContext);

  return (
    <TouchableHighlight
      style={[
        style.container,
        {
          backgroundColor: backgroundColor
            ? backgroundColor
            : theme.background.default,
          borderColor: theme.divider,
        },
      ]}
      onPress={onPress}
    >
      <View>
        <Text
          style={[
            style.promtText,
            { color: color ? color : theme.typography.primary },
          ]}
        >
          {title}
        </Text>
      </View>
    </TouchableHighlight>
  );
}

const style = StyleSheet.create({
  promtText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  container: {
    borderWidth: 1,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingHorizontal: 15,
    paddingVertical: 10,
    height: 46,
  },
});

export default MiniButton;
