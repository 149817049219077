import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useNavigation } from "@react-navigation/native";
import { UserContext } from "../contexts/user-context";
import { PostContext } from "../contexts/post-context";
import moment from "moment-twitter";

function PostTitleContainer() {
  const { userName, location, timing, userID } = useContext(PostContext);
  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);

  const [navAvailable, setNavAvailable] = useState(false);

  const navigation = useNavigation();
  const navState = navigation.getState();

  useEffect(() => {
    if (
      navState.routeNames[navState.index] !== "UserProfile" &&
      navState.routeNames[navState.index] !== "OtherProfile"
    ) {
      setNavAvailable(true);
    }
  }, []);

  const renderTitleSecondaryText = (text) => (
    <Text
      style={[styles.titleSecondaryText, { color: theme.typography.secondary }]}
    >
      {text}
    </Text>
  );

  return (
    <View style={styles.container}>
      <View style={styles.title}>
        <TouchableOpacity
          onPress={() => {
            if (navAvailable) {
              if (user && user.userID === userID) {
                navigation.navigate("Profile");
              } else {
                navigation.navigate("OtherProfile", {
                  userID: userID,
                  screenName: userName,
                });
              }
            }
          }}
        >
          <Text
            style={[
              styles.titlePrimaryText,
              { color: theme.typography.primary },
            ]}
          >
            {userName}
          </Text>
        </TouchableOpacity>
        <View style={styles.secondaryTextContainer}>
          {renderTitleSecondaryText(timing.reportType)}
          {renderTitleSecondaryText(" - ")}
          {renderTitleSecondaryText(
            timing.reportType === "Session"
              ? moment(timing.endTime).twitterShort()
              : moment(timing.startTime).twitterShort()
          )}
        </View>
      </View>
      <View style={styles.subtitle}>
        <Text
          style={[
            styles.titleSecondaryText,
            { color: theme.typography.secondary },
          ]}
        >
          {location.surfSpot.item}, {location.region}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  title: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  subtitle: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  titlePrimaryText: {
    fontSize: 16,
  },
  titleSecondaryText: {
    fontSize: 12,
  },
  secondaryTextContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
});

export default PostTitleContainer;
