import { createNativeStackNavigator } from "@react-navigation/native-stack";
import NewSessionTiming from "./NewSessionTiming";
import NewSessionSetup from "./NewSessionLocation";
import NewSessionRating from "./NewSessionRating";

const Stack = createNativeStackNavigator();

function NewSession({}) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Session Location" component={NewSessionSetup} />
      <Stack.Screen name="Session Timing" component={NewSessionTiming} />
      <Stack.Screen name="Session Rating" component={NewSessionRating} />
    </Stack.Navigator>
  );
}

export default NewSession;
