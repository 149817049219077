import React, { useMemo, useContext, useState, useEffect } from "react";
import { StyleSheet, ScrollView, ActivityIndicator, View } from "react-native";
import SpotDetailsPageHeader from "./SpotDetailsPageHeader";
import HourlyWeatherContainer from "./HourlyWeatherContainer";
import WeeklyWeatherContainer from "./WeeklyWeatherContainer";
import LocationBio from "../LocationBio";
import WeatherDetailsPopUp from "./WeatherDetailsPopUp";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../contexts/weather-context";
import { ThemeContext } from "../../contexts/theme-context";
import WaveIcon from "../../svgs/WaveIcon";
import {
  getDailyExtremes,
  calculateWeeklyWindExtremes,
  calculateWeeklySwellExtremes,
} from "../../utilFunctions/maxMinValueFunctions";
import Snackbar from "../../uiCommon/Snackbar";
import NavigationHeader from "../../containers/NavigationHeader";
import UserActivity from "../../profile/UserActivity";

const SpotDetails = ({ route, navigation }) => {
  const weather = useContext(WeatherContext);
  const { spotID, name, region, saved } = route.params;
  const theme = useContext(ThemeContext);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [forecastType, setForecastType] = useState({
    icon: <WaveIcon color={theme.typography.primary} />,
    title: "Wave",
  });
  const [spotSaved, setSpotSaved] = useState(saved);
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [activePath, setActivePath] = useState("Forecast");
  const paths = ["Forecast", "Activity"];

  // Calculate Location Weather Extremes
  const {
    weeklyWindExtremes,
    weeklySwellExtremes,
    weeklyTideExtremes,
    dailySustainedExtremes,
    dailyGustExtremes,
  } = useMemo(() => {
    const sustainedExtremes = getDailyExtremes(
      "windSustained",
      weather,
      spotID
    );
    const gustExtremes = getDailyExtremes("windGust", weather, spotID);
    const swellExtremes = getDailyExtremes("swellHeight", weather, spotID);
    const tideExtremes = getDailyExtremes("tideHeight", weather, spotID);

    return {
      dailyGustExtremes: gustExtremes,
      dailySustainedExtremes: sustainedExtremes,
      weeklyWindExtremes: calculateWeeklyWindExtremes(
        sustainedExtremes,
        gustExtremes
      ),
      weeklySwellExtremes: calculateWeeklySwellExtremes(swellExtremes),
      weeklyTideExtremes: calculateWeeklySwellExtremes(tideExtremes),
    };
  }, [weather, spotID]);

  useEffect(() => {
    navigation.setOptions({
      title: name,
    });
  }, [name]);

  return (
    <WeatherChartContext.Provider
      value={{
        selectedDay,
        setSelectedDay,
        spotID,
        forecastType,
        setForecastType,
        weeklyWindExtremes,
        weeklySwellExtremes,
        weeklyTideExtremes,
        dailySustainedExtremes,
        dailyGustExtremes,
        activeTooltip,
        setActiveTooltip,
      }}
    >
      <SpotDetailsPageHeader
        name={name}
        region={region}
        spotSaved={spotSaved}
        navigation={navigation}
        setSnackBarMsg={setSnackBarMsg}
        setShowSnackbar={setShowSnackbar}
        setSpotSaved={setSpotSaved}
      />
      <NavigationHeader
        paths={paths}
        activePath={activePath}
        setActivePath={setActivePath}
      />
      <Snackbar
        message={snackBarMsg}
        setIsVisible={setShowSnackbar}
        isVisible={showSnackbar}
      />
      <ScrollView
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator={false}
      >
        {activePath === "Forecast" ? (
          weather.weatherForecasts[spotID] ? (
            <>
              <HourlyWeatherContainer />
              <WeeklyWeatherContainer />
              <LocationBio />
            </>
          ) : (
            <ActivityIndicator size="large" color={theme.typography.primary} />
          )
        ) : null}
        {activePath === "Activity" && (
          <View style={{ width: "100%" }}>
            <UserActivity activityQuery={{ "location.surfSpot.key": spotID }} />
          </View>
        )}
      </ScrollView>
      {selectedDay !== -1 && <WeatherDetailsPopUp />}
    </WeatherChartContext.Provider>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
});

export default SpotDetails;
