import { StyleSheet, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";
import PageHeader from "../../containers/PageHeader";
import LocationSearch from "./LocationSearch";

function WeatherSpotSearch({ navigation }) {
  const theme = useContext(ThemeContext);

  return (
    <View style={styles.contentContainer}>
      <PageHeader
        header={"Search"}
        leftButton={
          <AntDesign name="back" size={30} color={theme.typography.primary} />
        }
        leftPress={() => {
          navigation.goBack();
        }}
      />
      <LocationSearch navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "2.5%",
  },
});

export default WeatherSpotSearch;
