import React, { useRef, useContext } from "react";
import { PanResponder } from "react-native";
import { ChartContext } from "../../../../contexts/chart-context";
import { WeatherChartContext } from "../../../../contexts/weather-context";

const ChartPanResponder = () => {
  const {
    XAxisStartPosition,
    PlotAreaWidth,
    dateList,
    setPanPositionX,
    panPositionX,
  } = useContext(ChartContext);
  const { setActiveTooltip } = useContext(WeatherChartContext);

  let panPositionTimeout = null;

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderTerminationRequest: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        clearTimeout(panPositionTimeout); // Clear any previous timeout
        panPositionTimeout = setTimeout(() => {
          if (gestureState.x0 || gestureState.y0) {
            updatePosition(evt.nativeEvent.locationX);
            setActiveTooltip(true);
          }
        }, 200); // 200ms delay to differentiate between pan and screen swipe

        return true;
      },
      onPanResponderMove: (evt, gestureState) => {
        if (panPositionX > -1) {
          updatePosition(evt.nativeEvent.locationX);
          setActiveTooltip(true);
        }
        return true;
      },
      onPanResponderRelease: () => {
        setPanPositionX(-1);
        clearTimeout(panPositionTimeout);
        setActiveTooltip(false);
      },
    })
  );

  const updatePosition = (x) => {
    const size = dateList.length;
    const xN = XAxisStartPosition + PlotAreaWidth; //xN position
    const xDistance = PlotAreaWidth / (size - 1); // The width of each coordinate point
    if (x <= XAxisStartPosition) {
      x = XAxisStartPosition;
    }
    if (x >= xN) {
      x = xN;
    }
    let value = ((x - XAxisStartPosition) / xDistance).toFixed(0);
    if (value >= size - 1) {
      value = size - 1; // Out of chart range, automatic correction
    }

    setPanPositionX(Number(value));
  };

  return panResponder; //.current.panHandlers; // Return the panHandlers
};

export default ChartPanResponder;
