import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableHighlight,
} from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext, useState } from "react";
import moment from "moment";
import { Svg, Rect, Defs, LinearGradient, Stop } from "react-native-svg";

function TimeSelection({ time, setTime }) {
  const theme = useContext(ThemeContext);

  return (
    <View style={{ marginTop: 10 }}>
      <View style={[styles.timeSelectionContainer]}>
        {/* <Svg
          height={80}
          width={"100%"}
          style={StyleSheet.absoluteFill}
          viewBox="0 0 80 550"
        >
          <Rect
            height={60}
            width={"100%"}
            fill={theme.background.paper_e1}
            y={20}
          />
          <Defs>
            <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
              <Stop
                offset="0"
                stopColor={theme.background.default}
                stopOpacity="1"
              />
              <Stop
                offset="25%"
                stopColor={theme.background.default}
                stopOpacity="0"
              />
              <Stop
                offset="75%"
                stopColor={theme.background.default}
                stopOpacity="0"
              />
              <Stop
                offset="1"
                stopColor={theme.background.default}
                stopOpacity="1"
              />
            </LinearGradient>
          </Defs>
          <Rect height={80} width={"100%"} fill={"url(#grad)"} />
        </Svg> */}
        <ScrollView horizontal={true} showsVerticalScrollIndicator={false}>
          {Array(24)
            .fill(0)
            .map((_, idx) => {
              return (
                <View key={idx} style={styles.buttonContainer}>
                  <Text
                    style={[
                      styles.timeSelectorText,
                      { color: theme.typography.primary },
                    ]}
                  >
                    {idx === 12 ? "Noon" : ""}
                    {idx === 0 ? "AM" : ""}
                    {idx === 23 ? "PM" : ""}
                  </Text>
                  <TouchableHighlight
                    style={[
                      styles.timeSelectionBox,
                      {
                        backgroundColor:
                          time === idx
                            ? theme.buttons.selected
                            : theme.background.paper_e1,
                      },
                    ]}
                    activeOpacity={0.1}
                    underlayColor={theme.buttons.hover}
                    onPress={() => {
                      setTime(idx);
                    }}
                  >
                    <Text
                      style={[
                        styles.timeSelectorText,
                        { color: theme.typography.primary },
                      ]}
                    >
                      {moment(idx, "H").format("h")}
                    </Text>
                  </TouchableHighlight>
                </View>
              );
            })}
        </ScrollView>
        <Svg
          height={80}
          width={"15%"}
          style={StyleSheet.absoluteFill}
          /* viewBox="0 0 80 50" */
        >
          <Defs>
            <LinearGradient id="text_grad" x1="0" y1="0" x2="1" y2="0">
              <Stop
                offset="0%"
                stopColor={theme.background.default}
                stopOpacity="1"
              />
              <Stop
                offset="100%"
                stopColor={theme.background.default}
                stopOpacity="0"
              />
            </LinearGradient>
          </Defs>
          <Rect height={80} width={"100%"} fill={"url(#text_grad)"} />
        </Svg>

        <Svg
          height={80}
          width={"15%"}
          style={[{ position: "absolute", right: 0 }]}
          /* viewBox="0 0 80 50" */
        >
          <Defs>
            <LinearGradient id="text_grad" x1="0" y1="0" x2="1" y2="0">
              <Stop
                offset="0%"
                stopColor={theme.background.default}
                stopOpacity="0"
              />
              <Stop
                offset="100%"
                stopColor={theme.background.default}
                stopOpacity="1"
              />
            </LinearGradient>
          </Defs>
          <Rect height={80} width={"100%"} fill={"url(#text_grad)"} />
        </Svg>
      </View>
      <HelperLabels />
    </View>
  );
}

function HelperLabels() {
  const theme = useContext(ThemeContext);
  return (
    <View style={[styles.ratingLabels]}>
      <Text
        style={[styles.ratingLabelsText, { color: theme.typography.secondary }]}
      >
        Earlier
      </Text>
      <Text
        style={[styles.ratingLabelsText, { color: theme.typography.secondary }]}
      >
        Later
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  componentContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  timeSelectionContainer: {
    flexDirection: "row",
  },
  buttonContainer: {
    flexDirection: "column",
    width: 40,
    height: 80,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  timeSelectionBox: {
    width: 40,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  timeSelectorText: { fontsize: 20, fontWeight: "bold" },
  ratingLabels: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  ratingLabelsText: { fontSize: 12, margin: 8 },
});

export default TimeSelection;
