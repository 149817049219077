import { createContext } from "react";

export const themes = {
  light: {
    dark: false,
    colors: {
      primary: "#F5F5F5",
      background: "#F5F5F5",
      card: "#F5F5F5",
      text: "rgba(38, 153, 251, 1)",
      border: "rgba(0, 0, 0, 0.12)",
      notification: "#F5F5F5",
    },
    typography: {
      primary: "#1a1a1a" /* "rgba(0, 0, 0, 0.87)" */,
      secondary: "#666666" /* "rgba(0, 0, 0, 0.6)" */,
      disabled: "#999999" /* "rgba(0, 0, 0, 0.38)" */,
      alert: "rgba(215,0,21,1)",
    },
    buttons: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04))",
      selected: "rgba(0, 0, 0, 0.16)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      default: "#F5F5F5",
      paper_e1: "#E5E5E5",
      paper_e2: "#D5D5D5",
      default_blur: "rgba(255, 255, 255,.8)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    accentColors: { theme: "#008080", gold: "#DAA520" },
    statusbar: "dark-content",
  },

  dark: {
    dark: true,
    colors: {
      primary: "#121212",
      background: "#121212",
      card: "#121212",
      text: "rgba(38, 153, 251, 1)",
      border: "rgba(255, 255, 255, 0.12)",
      notification: "rgb(58, 59, 60)",
    },
    typography: {
      primary: "#F5F5F5",
      secondary: "#b3b3b3" /* "rgba(255, 255, 255, 0.7)" */,
      disabled: "#808080" /* "rgba(255, 255, 255, 0.5)" */,
      alert: "#993333",
    },
    buttons: {
      active: "rgba(255, 255, 255, 0.54)",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      default: "#121212",
      //paper_e1: "#222222",
      paper_e1: "#1c1c1c",
      paper_e2: "#323232",
      default_blur: "rgba(18, 18, 18,.8)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    accentColors: { theme: "#008080", gold: "#DAA520" },
    statusbar: "light-content",
  },
};

export const ThemeContext = createContext(themes.light);
