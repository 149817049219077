import { View, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import NavigationHeader from "../containers/NavigationHeader";
import Logo from "../svgs/LogoIcon";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";
import UserActivity from "../profile/UserActivity";

function LatestActivity({ navigation }) {
  const [activePath, setActivePath] = useState("Latest");
  /* const paths = ["FOR YOU", "TRENDING", "REGIONAL"]; */
  const paths = ["Latest"];
  const theme = useContext(ThemeContext);

  useEffect(() => {
    navigation.setOptions({
      title: activePath + " Activity",
    });
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ justifyContent: "center", flexDirection: "row" }}>
        <View style={{ margin: 4, height: 20, width: 20 }}>
          <Logo stroke={theme.typography.primary} />
        </View>
      </View>

      <NavigationHeader
        paths={paths}
        activePath={activePath}
        setActivePath={setActivePath}
      />
      <UserActivity activityQuery={{ userID: { $exists: true } }} />
    </View>
  );
}
const styles = StyleSheet.create({
  container: { flex: 1 },
});

export default LatestActivity;
