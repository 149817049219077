import {
  StyleSheet,
  TouchableHighlight,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../contexts/theme-context";
import { UserContext, ClientIDs } from "../contexts/user-context";
import { getUserInfo } from "../api/user";
import { AntDesign } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import Checkbox from "../uiCommon/CheckBox";
import TermsAndConditions from "../admin/TermsAndConditions";
import PrivacyPolicy from "../admin/PrivacyPolicy";

// Auth Work
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import AsyncStorage from "@react-native-async-storage/async-storage";
import FullScreenModal from "../uiCommon/FullScreenModal";
import HelperText from "../uiCommon/HelperText";
import PageHeader from "../containers/PageHeader";

WebBrowser.maybeCompleteAuthSession();

export default function AuthStack() {
  const theme = useContext(ThemeContext);
  const userContext = useContext(UserContext);
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(null);
  // Terms and Conditions
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const handleViewTerms = () => {
    setShowTermsModal(true);
  };
  const handleViewPrivacy = () => {
    setShowPrivacyModal(true);
  };
  const handleModalClose = () => {
    setShowTermsModal(false);
    setShowPrivacyModal(false);
  };

  // AUTH WORK
  const [request, response, promptAsync] = Google.useAuthRequest(ClientIDs);

  useEffect(() => {
    handleGoogleSignIn();
  }, [response]);

  const handleGoogleSignIn = async () => {
    if (response?.type === "success") {
      setLoading(true);
      const token = response.authentication.accessToken;
      try {
        const response = await fetch(
          "https://www.googleapis.com/userinfo/v2/me",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const user = await response.json();

        await AsyncStorage.setItem("@user", user.id);

        const requestData = {
          queryTerms: {
            userID: user.id,
            signInFlag: true,
            settings: {
              screenName: user.name ? user.name : "NewUser",
              email: user.email,
            },
          },
        };

        const data = await getUserInfo(requestData);
        setLoading(false);

        if (data.err) {
          setHelperText(data.msg);
        } else {
          userContext.setUser(data.usr);
          if (!helperText) {
            setHelperText(null);
          }
        }
      } catch (error) {
        setHelperText("Oops, something went wrong..");
      }
    } else if (!response) {
      // first round response === null
    } else {
      setHelperText("Oops, something went wrong..");
    }
  };

  return (
    <>
      {userContext.user === null && (
        <FullScreenModal
          width={showTermsModal || showPrivacyModal ? "95%" : "60%"}
          height={showTermsModal || showPrivacyModal ? "75%" : "auto"}
          message={
            <>
              <PageHeader
                header={showTermsModal || showPrivacyModal ? "" : "Welcome!"}
                rightButton={
                  <Feather
                    name="x"
                    size={24}
                    color={theme.typography.primary}
                  />
                }
                rightPress={() => {
                  if (showTermsModal || showPrivacyModal) {
                    handleModalClose();
                  } else {
                    navigation.navigate("Home");
                    setLoading(false);
                  }
                }}
              />
              <View style={[styles.signInContainer]}>
                {!showTermsModal && !showPrivacyModal ? (
                  loading ? (
                    <ActivityIndicator
                      size="large"
                      color={theme.typography.primary}
                    />
                  ) : (
                    <>
                      <TouchableHighlight
                        style={[{ width: "75%", marginBottom: 10 }]}
                        disabled={!request}
                        onPress={() => {
                          promptAsync();
                        }}
                      >
                        <View
                          style={[
                            styles.signInButton,
                            { backgroundColor: theme.buttons.active },
                          ]}
                        >
                          <Text
                            style={[
                              styles.signInButtonText,
                              { color: theme.typography.primary },
                            ]}
                          >
                            Continue with Google
                          </Text>
                          <AntDesign
                            name="google"
                            size={24}
                            color={theme.typography.primary}
                          />
                        </View>
                      </TouchableHighlight>

                      <Text
                        style={{
                          textAlign: "center",
                          color: theme.typography.secondary,
                        }}
                      >
                        By clicking "Continue with Google", you agree to our{" "}
                        <TouchableOpacity onPress={handleViewTerms}>
                          <Text style={{ color: theme.accentColors.theme }}>
                            Terms and Conditions
                          </Text>
                        </TouchableOpacity>{" "}
                        and acknowledge our{" "}
                        <TouchableOpacity onPress={handleViewPrivacy}>
                          <Text style={{ color: theme.accentColors.theme }}>
                            Privacy Policy.
                          </Text>
                        </TouchableOpacity>
                      </Text>

                      <HelperText text={helperText} alert={true} />
                    </>
                  )
                ) : (
                  <ScrollView
                    style={{ flex: 1 }}
                    showsVerticalScrollIndicator={false}
                  >
                    {showTermsModal && <TermsAndConditions />}
                    {showPrivacyModal && <PrivacyPolicy />}
                  </ScrollView>
                )}
              </View>
            </>
          }
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  signInContainer: {
    width: "90%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    marginBottom: 20,
  },
  signInButton: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 10,
    height: 46,
    borderRadius: 2,
  },
  signInButtonText: {
    fontSize: 16,
    textAlign: "center",
  },
});
