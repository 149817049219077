import React, { useState, useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { ThemeContext } from "../contexts/theme-context";

function RadioButton({ options, selected, setSelected, stacked }) {
  const theme = useContext(ThemeContext);

  const handleOptionSelect = (optionLabel) => {
    setSelected(optionLabel);
  };

  const renderRadioButtons = () => {
    return options.map((option, idx) => (
      <View
        style={[
          styles.option,
          {
            paddingRight: !stacked ? (idx !== options.length - 1 ? 10 : 0) : 0,
            paddingBottom: stacked ? (idx !== options.length - 1 ? 10 : 0) : 0,
          },
        ]}
        key={option.id}
      >
        <TouchableOpacity onPress={() => handleOptionSelect(option.label)}>
          <View
            style={[
              styles.outerCicle,
              {
                borderColor: theme.divider,
                backgroundColor: theme.background.default,
              },
            ]}
          >
            <View
              style={[
                selected === option.label
                  ? styles.selectedCircle
                  : styles.circle,
                {
                  borderColor: theme.divider,
                  backgroundColor:
                    selected === option.label
                      ? theme.typography.disabled
                      : theme.background.default,
                },
              ]}
            ></View>
          </View>
        </TouchableOpacity>
        <View style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <Text
            style={[styles.optionLabel, { color: theme.typography.primary }]}
          >
            {option.label}
          </Text>
          {option.description && (
            <Text
              style={[
                styles.optionDescription,
                { color: theme.typography.secondary },
              ]}
            >
              {option.description}
            </Text>
          )}
        </View>
      </View>
    ));
  };

  return (
    <View
      style={[
        styles.container,
        {
          flexDirection: stacked ? "column" : "row",
        },
      ]}
    >
      {renderRadioButtons()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  option: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  optionLabel: { fontSize: 14 },
  optionDescription: { fontSize: 12 },
  outerCicle: {
    width: 20,
    aspectRatio: 1,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "gray",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  circle: {
    width: 10,
    aspectRatio: 1,
    borderRadius: 6,
    borderWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  selectedCircle: {
    width: 10,
    aspectRatio: 1,
    borderRadius: 6,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default RadioButton;
