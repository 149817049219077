import React from "react";
import { StyleSheet, View } from "react-native";
import Svg, { Circle, Path, G } from "react-native-svg";

function WeatherArrow({ color, strokeColor, rotation, chartWidth }) {
  return (
    <View style={[styles.container]}>
      <Svg
        style={[styles.svg, { width: chartWidth ? chartWidth : 45 }]}
        viewBox="0 0 30 30"
      >
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={30}
          height={30}
          fill="none"
        >
          <G x={0} y={0} rotation={rotation} originX={15} originY={15}>
            <Path
              fill={color}
              stroke={strokeColor}
              strokeWidth={0.55}
              d="M14.161 2.461c.297-.766 1.38-.766 1.678 0l8.075 20.798c.314.809-.586 1.55-1.32 1.087l-7.2-4.54a.9.9 0 0 0-.966.004l-7.002 4.497c-.733.47-1.64-.271-1.326-1.083L14.161 2.46Z"
            />
            <Circle cx={15} cy={15} r={14.85} stroke="blue" strokeWidth={0} />
          </G>
        </Svg>
      </Svg>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  svg: {
    width: 40,
    aspectRatio: 1,
  },
});

export default WeatherArrow;
