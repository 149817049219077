import React from "react";
import { StyleSheet, View } from "react-native";
import Svg, { Rect, Path, Circle, G } from "react-native-svg";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";

const SliderChart = ({
  chartWidth,
  minRot = 0,
  maxRot = 0,
  localMin = 5,
  localMax = 5,
  absoluteMin = 0,
  absoluteMax = 10,
}) => {
  const theme = useContext(ThemeContext);

  const compassDiameter = 30;
  const lineLength = 150;
  const lineHeight = 6;

  return (
    <View style={[styles.container]}>
      <Svg
        style={[styles.svg, { width: chartWidth }]}
        viewBox={`0 0 ${lineLength + compassDiameter} ${compassDiameter}`}
      >
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={lineLength + compassDiameter}
          height={compassDiameter}
          fill="none"
        >
          <Rect
            width={lineLength}
            height={lineHeight}
            x={compassDiameter / 2}
            y={compassDiameter / 2 - lineHeight / 2}
            fill={theme.typography.disabled}
            rx={lineHeight / 2}
          />
          <Rect
            width={
              ((localMax - localMin) / (absoluteMax - absoluteMin)) * lineLength
            }
            height={lineHeight}
            x={
              compassDiameter / 2 +
              ((localMin - absoluteMin) / (absoluteMax - absoluteMin)) *
                lineLength
            }
            y={compassDiameter / 2 - lineHeight / 2}
            fill={theme.typography.secondary}
            rx={lineHeight / 2}
          />
          {/* LOW ARROW */}
          <G
            x={
              ((localMin - absoluteMin) / (absoluteMax - absoluteMin)) *
              lineLength
            }
            y={compassDiameter / 2 - compassDiameter / 2}
            rotation={minRot}
            originX={compassDiameter / 2}
            originY={compassDiameter / 2}
          >
            <Path
              fill={theme.accentColors.theme}
              stroke={theme.background.default}
              strokeWidth={0.55}
              d="M14.161 2.461c.297-.766 1.38-.766 1.678 0l8.075 20.798c.314.809-.586 1.55-1.32 1.087l-7.2-4.54a.9.9 0 0 0-.966.004l-7.002 4.497c-.733.47-1.64-.271-1.326-1.083L14.161 2.46Z"
            />
            <Circle cx={15} cy={15} r={14.85} stroke="blue" strokeWidth={0} />
          </G>
          {/* UPPER ARROW */}
          <G
            x={
              ((localMin - absoluteMin + (localMax - localMin)) /
                (absoluteMax - absoluteMin)) *
              lineLength
            }
            y={compassDiameter / 2 - compassDiameter / 2}
            rotation={maxRot}
            originX={compassDiameter / 2}
            originY={compassDiameter / 2}
          >
            <Path
              fill={theme.accentColors.theme}
              stroke={theme.background.default}
              strokeWidth={0.55}
              d="M14.161 2.461c.297-.766 1.38-.766 1.678 0l8.075 20.798c.314.809-.586 1.55-1.32 1.087l-7.2-4.54a.9.9 0 0 0-.966.004l-7.002 4.497c-.733.47-1.64-.271-1.326-1.083L14.161 2.46Z"
            />
            <Circle cx={15} cy={15} r={14.85} stroke="blue" strokeWidth={0.0} />
          </G>
        </Svg>
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  svg: {
    width: 400,
    aspectRatio: 5,
  },
});

export default SliderChart;
