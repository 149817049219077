import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { RouteContext } from "../contexts/route-context";
import { AntDesign } from "@expo/vector-icons";

function BottomTabBar({ state, descriptors, navigation }) {
  const theme = useContext(ThemeContext);
  const route = useContext(RouteContext);

  if (state.routeNames[state.index] === "New Session" || !route.tabNavState) {
    return null;
  }
  return (
    <View style={[styles.barContainer, { borderColor: theme.divider }]}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate({ name: route.name, merge: true });
          }
        };

        if (route.name === "New Session") return null;

        return (
          <TouchableOpacity
            key={index}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            style={[styles.tabButton]}
          >
            <options.tabBarIcon
              color={
                isFocused ? theme.typography.primary : theme.typography.disabled
              }
              size={24}
            />
            <Text
              style={{
                color: isFocused
                  ? theme.typography.primary
                  : theme.typography.disabled,
              }}
            >
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
      <View style={{ flex: 0.25, alignItems: "center" }}>
        <TouchableOpacity
          style={[
            styles.fab,
            {
              backgroundColor: theme.accentColors.theme,
            },
          ]}
          onPress={() => {
            navigation.navigate("New Session");
          }}
        >
          <AntDesign name="plus" size={30} color={theme.background.default} />
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default BottomTabBar;

const styles = StyleSheet.create({
  barContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    borderTopWidth: 1,
  },
  tabButton: {
    flex: 0.25,
    alignItems: "center",
    justifyContent: "center",
    height: 60,
  },
  fab: {
    width: 60,
    height: 60,
    borderRadius: 30,
    zIndex: 10,
    position: "absolute",
    bottom: 30,
    alignItems: "center",
    justifyContent: "center",
    // ios android shadows
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
});
