import { TouchableHighlight, Text, View, StyleSheet } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";

function TwoButtonSelection({ selected, setSelected, buttonTitles }) {
  const theme = useContext(ThemeContext);

  return (
    <View style={styles.buttonContainer}>
      {buttonTitles.map((title, idx) => {
        return (
          <TouchableHighlight
            style={[
              styles.button,
              {
                backgroundColor:
                  selected === title
                    ? theme.buttons.selected
                    : theme.background.paper_e1,
                borderLeftWidth: idx ? 1 : 0,
                borderColor: theme.divider,
              },
            ]}
            activeOpacity={0.1}
            underlayColor={theme.buttons.hover}
            onPress={() => {
              setSelected(title);
            }}
            key={idx}
          >
            <Text
              style={[
                styles.selectionText,
                { color: theme.typography.primary },
              ]}
            >
              {title}
            </Text>
          </TouchableHighlight>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    paddingBottom: 20,
    paddingTop: 20,
  },
  button: {
    height: 60,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  selectionText: { fontSize: 20, fontWeight: "bold" },
});

export default TwoButtonSelection;
