import { StyleSheet, TouchableHighlight, View, Text } from "react-native";
import { ThemeContext } from "../../../../contexts/theme-context";
import { useContext, useMemo } from "react";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../../../contexts/weather-context";
import moment from "moment";

const WeatherDetailsDateSelectorButton = ({ selected, index }) => {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const chart = useContext(WeatherChartContext);

  const formattedDay = useMemo(
    () => moment(weather.timeArray[index][0]).format("dd"),
    [weather.timeArray, index]
  );

  const formattedDate = useMemo(
    () => moment(weather.timeArray[index][0]).format("D"),
    [weather.timeArray, index]
  );

  return (
    <View style={[styles.buttonContainer]}>
      <Text style={[styles.buttonText, { color: theme.typography.secondary }]}>
        {formattedDay}
      </Text>
      <TouchableHighlight
        style={[
          styles.button,
          {
            backgroundColor: selected
              ? theme.buttons.selected
              : theme.background.default,
          },
        ]}
        onPress={() => {
          chart.setSelectedDay(index);
        }}
      >
        <Text
          style={[styles.buttonText, { color: theme.typography.secondary }]}
        >
          {formattedDate}
        </Text>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 40,
    aspectRatio: 1,
    borderRadius: 25,
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  buttonText: { fontSize: 16 },
});

export default WeatherDetailsDateSelectorButton;
