export function replaceNullValues(array1, array2) {
  // Check if both arrays have the same length
  /* if (array1.length !== array2.length) {
    throw new Error("Arrays must have the same length");
  } */

  // Iterate over the arrays and replace null values

  for (let i = 0; i < array1.length; i++) {
    if (isNaN(array1[i])) {
      array1[i] = array2[i];
    }
  }
  return array1;
}
