import React, { useContext } from "react";
import { ThemeContext } from "../../../../contexts/theme-context";
import { ChartContext } from "../../../../contexts/chart-context";
import { G, Line, Circle, Defs, LinearGradient, Stop } from "react-native-svg";
import {
  WeatherChartContext,
  WeatherContext,
} from "../../../../contexts/weather-context";
import { Rect } from "react-native-svg";

function ChartCurrentHourIndicator({ x, y, ticks }) {
  const theme = useContext(ThemeContext);
  const { primaryConditionData, dateList, apx, gridMin } =
    useContext(ChartContext);
  const { currentHour } = useContext(WeatherContext);
  const { selectedDay } = useContext(WeatherChartContext);

  const hourIndex = dateList.indexOf(currentHour);

  //only returns marker of today
  if (selectedDay) {
    return null;
  }

  return (
    <>
      <Rect
        x="0"
        y={y(ticks[ticks.length - 1])}
        width={x(hourIndex)}
        height={y(gridMin) - y(ticks[ticks.length - 1])}
        fill={theme.divider}
        opacity={0.3}
      />
      <G x={x(hourIndex)} key="tooltip">
        <G x={x}>
          <Line
            y1={y(ticks[ticks.length - 1])}
            y2={y(gridMin)}
            stroke={theme.divider}
            strokeWidth={apx(1)}
            /* strokeDasharray={[6, 3]} */
          />

          <Circle
            cy={y(primaryConditionData[hourIndex])}
            r={apx(10 / 2)}
            stroke={theme.background.default}
            strokeWidth={apx(2)}
            fill={theme.typography.secondary}
          />
        </G>
      </G>
    </>
  );
}

export default ChartCurrentHourIndicator;
