import moment from "moment";
import React, { useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ThemeContext } from "../../../../contexts/theme-context";

function GraphOverviewTide({ tidePredictions, units }) {
  const theme = useContext(ThemeContext);
  return (
    <View style={styles.container}>
      {tidePredictions.map((read, index) => (
        <View key={index} style={styles.readContainer}>
          <View style={styles.timeContainer}>
            <Text
              style={[
                styles.text,
                { color: theme.typography.primary, whiteSpace: "nowrap" },
              ]}
            >
              {moment.utc(read.t, "YYYY-MM-DD HH:mm").local().format("h:mm a")}
            </Text>
          </View>
          <View style={styles.typeContainer}>
            <Text style={[styles.text, { color: theme.typography.primary }]}>
              {read.type}
            </Text>
          </View>
          <View style={styles.heightContainer}>
            <Text style={[styles.text, { color: theme.typography.primary }]}>
              {read.v.slice(0, -2) + units}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
  },
  readContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
  },
  timeContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    flex: 1,
  },
  typeContainer: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    alignItems: "flex-end",
  },
  text: { fontSize: 12 },
  heightContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    flex: 1,
    width: "100%",
  },
});

export default GraphOverviewTide;
