import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, TextInput, Text, ScrollView } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

import PageHeader from "../containers/PageHeader";
import RadioButton from "../uiCommon/RadioButton";
import SingleSelectDropdown from "../uiCommon/SingleSelectDropDown";
import SoloButton from "../uiCommon/SoloButton";
import MiniButton from "../uiCommon/MiniButton";
import { ThemeContext } from "../contexts/theme-context";
import { UserContext } from "../contexts/user-context";
import { RouteContext } from "../contexts/route-context";
import { putUserInfo } from "../api/user";
import ScreenNameInput from "./settings/ScreenNameInput";
import Snackbar from "../uiCommon/Snackbar";

function ProfileSettings({ navigation }) {
  const theme = useContext(ThemeContext);
  const { user, setUser } = useContext(UserContext);
  const route = useContext(RouteContext);

  const [screenName, setScreenName] = useState(user.settings.screenName);
  const [region, setRegion] = useState("Pacific Northwest");
  const [privacy, setPrivacy] = useState("Public");
  const [displayUnits, setDisplayUnits] = useState("Imperial");
  const [confirmSignOut, setConfirmSignOut] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = useState(false);

  const handleSave = async () => {
    const settings = {
      ...user.settings,
      screenName: screenName,
      region: region,
      privacy: privacy,
      displayUnits: displayUnits,
    };
    const response = await putUserInfo({
      userID: user.userID,
      ...user,
      settings: { settings: settings },
    });

    await setUser({ ...user, settings });

    if (response.acknowledged) {
      setSnackbarMSG("Settings Updated!");
    } else {
      setSnackbarMSG("Oops, something went wrong!");
    }
    setShowSnackbar(true);
  };

  useEffect(() => {
    route.setTabNavState(false);
  }, []);

  const handleSignOut = () => {
    navigation.navigate("UserProfile");
    AsyncStorage.removeItem("@user");
    setUser(null);
    route.setTabNavState(true);
  };

  return (
    <View style={styles.container}>
      <PageHeader
        header="Settings"
        leftButton={
          <AntDesign name="back" size={30} color={theme.typography.primary} />
        }
        leftPress={() => {
          route.setTabNavState(true);
          navigation.goBack();
        }}
      />

      <ScrollView style={{ paddingBottom: 100 }}>
        <Text style={[styles.title, { color: theme.typography.primary }]}>
          Alter Screen Name
        </Text>
        <View
          style={[
            styles.settingsBlock,
            { backgroundColor: theme.background.paper_e1 },
          ]}
        >
          <ScreenNameInput
            screenName={screenName}
            setScreenName={setScreenName}
          />
        </View>

        <Text style={[styles.title, { color: theme.typography.primary }]}>
          User Region
        </Text>
        <View
          style={[
            styles.settingsBlock,
            { backgroundColor: theme.background.paper_e1 },
          ]}
        >
          <SingleSelectDropdown
            options={["Pacific Northwest"].map((item, idx) => {
              return { key: idx, item: item };
            })}
            onSelect={(selection) => {
              setRegion(selection.item);
            }}
            selected={{ item: region }}
          />
        </View>

        <Text style={[styles.title, { color: theme.typography.primary }]}>
          Units
        </Text>
        <View
          style={[
            styles.settingsBlock,
            { backgroundColor: theme.background.paper_e1 },
          ]}
        >
          <RadioButton
            selected={displayUnits}
            setSelected={setDisplayUnits}
            options={[
              { label: "Imperial" },
              {
                label: "Metric",
              },
            ].map((item, idx) => {
              return { label: item.label, id: idx };
            })}
          />
        </View>

        <Text style={[styles.title, { color: theme.typography.primary }]}>
          Privacy
        </Text>
        <View
          style={[
            styles.settingsBlock,
            { backgroundColor: theme.background.paper_e1 },
          ]}
        >
          <RadioButton
            selected={privacy}
            setSelected={setPrivacy}
            options={[
              {
                label: "Private",
                description: "Visible to account holder only",
              },
              /* {
                label: "Friends",
                description: "Visible to account holder only and their friends",
              }, */
              { label: "Public", description: "Visible to all users" },
            ].map((item, idx) => {
              return {
                label: item.label,
                description: item.description,
                id: idx,
              };
            })}
            stacked={true}
          />
        </View>

        <Text style={[styles.title, { color: theme.typography.primary }]}>
          Sign Out
        </Text>
        <View
          style={[
            styles.settingsBlock,
            { backgroundColor: theme.background.paper_e1 },
          ]}
        >
          {confirmSignOut ? (
            <MiniButton
              onPress={handleSignOut}
              title="Confirm Sign Out"
              backgroundColor={theme.background.paper_e1}
              color={theme.typography.alert}
            />
          ) : (
            <MiniButton
              onPress={() => setConfirmSignOut(true)}
              title="Sign Out"
              backgroundColor={theme.background.paper_e1}
            />
          )}
        </View>
      </ScrollView>
      <Snackbar
        message={snackbarMSG}
        isVisible={showSnackbar}
        setIsVisible={setShowSnackbar}
      />
      <SoloButton title="Save" submit={handleSave} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: "column" },
  settingsBlock: { marginTop: 8, padding: 10 },
  title: { fontSize: 16, fontWeight: "bold", marginTop: 20, marginLeft: 10 },
  subtitle: { fontSize: 14, fontWeight: "800", marginTop: 20, marginLeft: 10 },
  textInput: {
    borderWidth: 1,
    borderRadius: 2,
    paddingHorizontal: 15,
    paddingVertical: 10,
    height: 46,
    fontSize: 16,
    fontWeight: "bold",
  },
  button: { width: 100 },
});

export default ProfileSettings;
