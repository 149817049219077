import { ScrollView, StyleSheet, Text } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

const UserAnalytics = () => {
  /* const theme = useContext(ThemeContext);

  const commitsData = [
    { date: "2017-01-02", count: 1 },
    { date: "2017-01-03", count: 2 },
    { date: "2017-01-04", count: 3 },
    { date: "2017-01-05", count: 4 },
    { date: "2017-01-06", count: 5 },
    { date: "2017-01-30", count: 2 },
    { date: "2017-01-31", count: 3 },
    { date: "2017-03-01", count: 2 },
    { date: "2017-04-02", count: 4 },
    { date: "2017-03-05", count: 2 },
    { date: "2017-02-30", count: 4 },
  ];

  const chartConfig = {
    color: (opacity = 1) => `rgba(38, 153, 251, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false, // optional
    backgroundGradientFrom: theme.background.default,
    backgroundGradientTo: theme.background.default,
  };

  const data = {
    data: [0.4, 0.6, 0.8],
  };

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <ContributionGraph
        values={commitsData}
        endDate={new Date("2017-04-01")}
        numDays={105}
        width={300}
        height={220}
        chartConfig={chartConfig}
        onDayPress={(data) => {
          console.log(data);
        }}
      />

      <ProgressChart
        data={data}
        width={300}
        height={220}
        strokeWidth={16}
        radius={32}
        chartConfig={chartConfig}
        hideLegend={false}
      />
      <Text>AVG REVIEW</Text>
    </ScrollView>
  ); */
};
/* 
const styles = StyleSheet.create({
  container: { width: "100%", alignItems: "center" },
}); */

export default UserAnalytics;
