import { StyleSheet, View, Text } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext, useEffect, useState } from "react";
import WaveIcon from "../svgs/WaveIcon";
import TideIcon from "../svgs/TideIcon";
import WindIcon from "../svgs/WindIcon";
import CrowdIcon from "../svgs/CrowdIcon";
import PostConditionsContainer from "./PostConditionsContainer";
import { PostContext } from "../contexts/post-context";

function PostRatingContainer({ expand, type }) {
  const theme = useContext(ThemeContext);
  const { feedback } = useContext(PostContext);
  const [feedbackData, setFeedbackData] = useState({
    rating: 0,
    tags: [],
    icon: <></>,
  });

  useEffect(() => {
    switch (type) {
      case "Wave":
        setFeedbackData({
          rating: feedback.waves.rating,
          tags: feedback.waves.tags,
          icon: <WaveIcon color={theme.typography.secondary} />,
        });
        break;
      case "Wind":
        setFeedbackData({
          rating: feedback.wind.rating,
          tags: feedback.wind.tags,
          icon: <WindIcon color={theme.typography.secondary} />,
        });
        break;
      case "Tide":
        setFeedbackData({
          rating: feedback.tide.rating,
          tags: feedback.tide.tags,
          icon: <TideIcon color={theme.typography.secondary} />,
        });
        break;
      case "Crowd":
        setFeedbackData({
          rating: feedback.crowd.rating,
          tags: feedback.crowd.tags,
          icon: <CrowdIcon color={theme.typography.secondary} />,
        });
        break;
      default:
        break;
    }
  }, []);

  return (
    <View style={[styles.ratingContainer]}>
      <View style={[{ width: 20, height: 20 }]}>{feedbackData.icon}</View>
      <View style={[styles.ratingDetailContainer]}>
        <View style={[styles.ratingBarContainer]}>
          <View
            style={[
              styles.ratingBar,
              {
                width: (feedbackData.rating * 20).toString() + "%",
                backgroundColor:
                  feedbackData.rating === 5
                    ? theme.accentColors.gold
                    : theme.accentColors.theme,
                opacity: `${feedbackData.rating * 20}%`,
              },
            ]}
          ></View>
        </View>
        <View style={[styles.tagContainer]}>
          {feedbackData.tags.length === 0 ? (
            <Text
              style={[styles.tagText, { color: theme.typography.secondary }]}
            >
              -
            </Text>
          ) : (
            feedbackData.tags.map((tag, idx) => {
              return (
                <Text
                  key={idx}
                  style={[
                    styles.tagText,
                    { color: theme.typography.secondary },
                  ]}
                >
                  {idx !== 0 ? "\u00B7 " : ""}
                  {tag}{" "}
                </Text>
              );
            })
          )}
        </View>
        {expand ? <PostConditionsContainer type={type} /> : <></>}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  ratingContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 4,
    marginBottom: 4,
    width: "100%",
  },
  ratingDetailContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 10,
    flex: 1,
  },

  ratingBarContainer: {
    flexDirection: "column",
    justifyContent: "center",
    height: 4,
    width: "100%",
    marginBottom: 3,
  },
  ratingBar: { height: 4, borderRadius: 2 },
  tagContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  tagText: { fontSize: 12 },
});

export default PostRatingContainer;
