import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";

function NewSessionRatingButtons({ lowLabel, highLabel, rating, setRating }) {
  const theme = useContext(ThemeContext);

  return (
    <>
      <View style={[styles.ratingBoxes, { borderColor: theme.divider }]}>
        {Array(5)
          .fill(0)
          .map((_, idx) => {
            return (
              <TouchableOpacity
                style={[
                  styles.ratingBox,
                  {
                    // background color logic
                    backgroundColor:
                      idx + 1 <= rating
                        ? rating === 5
                          ? theme.accentColors.gold
                          : theme.accentColors.theme
                        : theme.background.default,

                    // zero rating container logic
                    borderTopLeftRadius: idx === 0 ? 12.5 : 0,
                    borderBottomLeftRadius: idx === 0 ? 12.5 : 0,
                    // largest rating container logic
                    borderTopRightRadius:
                      idx + 1 === rating || idx + 1 === 5 ? 12.5 : 0,
                    borderBottomRightRadius:
                      idx + 1 === rating || idx + 1 === 5 ? 12.5 : 0,
                    // last button logic
                  },
                ]}
                key={idx}
                activeOpacity={1}
                underlayColor={theme.buttons.active}
                onPress={() => {
                  setRating(idx + 1);
                }}
              >
                <View style={styles.ratingBoxTextContainer}>
                  <Text
                    style={[
                      styles.ratingBoxText,
                      { color: theme.typography.primary },
                    ]}
                  >
                    {idx + 1}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
      </View>
      <View style={[styles.ratingLabels]}>
        <Text
          style={[
            styles.ratingLabelsText,
            { color: theme.typography.secondary },
          ]}
        >
          {lowLabel}
        </Text>
        <Text
          style={[
            styles.ratingLabelsText,
            { color: theme.typography.secondary },
          ]}
        >
          {highLabel}
        </Text>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  ratingBoxes: {
    flexDirection: "row",
    borderRadius: 12.5,
    borderWidth: 1,
    width: "100%",
    justifyContent: "center",
  },
  ratingBox: { height: 25, flex: 1 },
  ratingBoxTextContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  ratingBoxText: { fontsize: 20, fontWeight: "bold" },
  ratingLabels: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  ratingLabelsText: { fontSize: 12, margin: 8 },
});

export default NewSessionRatingButtons;
