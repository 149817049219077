import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";

import WaveIcon from "../../svgs/WaveIcon";
import WindIcon from "../../svgs/WindIcon";
import TideIcon from "../../svgs/TideIcon";
import CrowdIcon from "../../svgs/CrowdIcon";
import Svg from "react-native-svg";

function RatingTypeIcon({ type }) {
  const theme = useContext(ThemeContext);
  let icon = <></>;
  switch (type) {
    case "waves":
      icon = <WaveIcon color={theme.typography.secondary} />;
      break;
    case "wind":
      icon = <WindIcon color={theme.typography.secondary} />;
      break;
    case "tide":
      icon = <TideIcon color={theme.typography.secondary} />;
      break;
    case "crowd":
      icon = <CrowdIcon color={theme.typography.secondary} />;
      break;
    default:
      break;
  }
  return icon;
}

const ratingSelectionConstants = {
  waves: {
    title: "Waves",
    icon: <RatingTypeIcon type={"waves"} />,
    lowLabel: "Bad",
    highLabel: "Good",
  },
  wind: {
    title: "Wind",
    icon: <RatingTypeIcon type={"wind"} />,
    lowLabel: "Bad",
    highLabel: "Good",
  },
  tide: {
    title: "Tide",
    icon: <RatingTypeIcon type={"tide"} />,
    lowLabel: "Bad",
    highLabel: "Good",
  },
  crowd: {
    title: "Crowd",
    icon: <RatingTypeIcon type={"crowd"} />,
    lowLabel: "Bad",
    highLabel: "Good",
  },
};

const ratingTypeConstants = (type) => {
  if (type === "waves") return ratingSelectionConstants.waves;
  if (type === "wind") return ratingSelectionConstants.wind;
  if (type === "tide") return ratingSelectionConstants.tide;
  if (type === "crowd") return ratingSelectionConstants.crowd;
};

export default ratingTypeConstants;
