import React, { useContext, useState, useEffect } from "react";
import { StyleSheet } from "react-native";
import Animated, {
  FadeIn,
  FadeOut,
  SlideInDown,
  SlideOutDown,
} from "react-native-reanimated";
import GestureRecognizer from "react-native-swipe-gestures";

import { ThemeContext } from "../contexts/theme-context";
import { WeatherChartContext } from "../contexts/weather-context";

function FullScreenDrawer({ open = false, children }) {
  const theme = useContext(ThemeContext);
  const weatherDetails = useContext(WeatherChartContext);

  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Animated.View
      style={[
        styles.alertContainer,
        { backgroundColor: theme.background.default_blur },
      ]}
      entering={FadeIn}
      exiting={FadeOut}
    >
      {isOpen && (
        <Animated.View
          style={[
            styles.modalContainer,
            {
              backgroundColor: theme.background.default,
              borderColor: theme.divider,
            },
          ]}
          entering={SlideInDown}
          exiting={SlideOutDown}
        >
          {children}
        </Animated.View>
      )}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  alertContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalContainer: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderWidth: 1,
    borderBottomWidth: 0,
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "98%",
  },
  gestContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default FullScreenDrawer;
