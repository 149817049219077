import { useContext, useMemo } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ThemeContext } from "../../../../contexts/theme-context";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../../../contexts/weather-context";
import WeatherDetailsDateSelectorButton from "./WeatherDetailesDateSelectorButton";
import moment from "moment";

function WeatherDetailsDateSelector() {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const chart = useContext(WeatherChartContext);

  const numDays = useMemo(() => Array(6).fill(0), []); // Memoize the array initialization

  const formattedDate = useMemo(
    () =>
      moment(weather.timeArray[chart.selectedDay][0]).format(
        "dddd, MMMM Do, YYYY"
      ), // Memoize the date formatting
    [weather.timeArray, chart.selectedDay]
  );

  return (
    <View style={[styles.contentContainer]}>
      <View style={[styles.buttonContainer]}>
        {numDays.map((_, idx) => {
          return (
            <WeatherDetailsDateSelectorButton
              key={idx}
              selected={chart.selectedDay === idx}
              index={idx}
            />
          );
        })}
      </View>
      <View style={[styles.displayContainer]}>
        <Text
          style={[styles.displayText, { color: theme.typography.secondary }]}
        >
          {formattedDate}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "90%",
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  displayContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  displayText: { fontSize: 16 },
});

export default WeatherDetailsDateSelector;
