import React, { useContext } from "react";
import { View } from "react-native";
import { YAxis, XAxis } from "react-native-svg-charts";
import { ThemeContext } from "../../../../contexts/theme-context";
import { ChartContext } from "../../../../contexts/chart-context";
import ChartPanResponder from "./ChartPanResponder";

function ChartAxis({ children }) {
  const theme = useContext(ThemeContext);
  const chart = useContext(ChartContext);

  const panHandler = ChartPanResponder();

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          height: chart.PlotAreaHeight,
          alignSelf: "stretch",
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end",
            width: chart.PlotAreaWidth,
          }}
          {...panHandler.current.panHandlers}
        >
          {children}
        </View>
        <YAxis
          style={{
            width: chart.YAxisWidth,
            borderLeftWidth: 1,
            borderLeftColor: theme.divider,
          }}
          numberOfTicks={5}
          data={chart.primaryConditionData}
          contentInset={chart.verticalContentInset}
          svg={{
            fontSize: chart.axisFontSize,
            fill: theme.typography.secondary,
            fontFamily: "sans-serif",
          }}
          min={chart.gridMin}
          max={chart.gridMax}
        />
      </View>
      <XAxis
        style={{
          alignSelf: "stretch",
          width: chart.ChartCanvasWidth,
          height: chart.XAxisHeight,
        }}
        data={chart.primaryConditionData}
        formatLabel={(value, index) => {
          if (index % 6 === 0)
            return moment(chart.dateList[value]).format("ha");
        }}
        contentInset={{
          left: chart.XAxisStartPosition,
          right: chart.YAxisWidth,
        }}
        svg={{
          fontSize: chart.axisFontSize,
          fill: theme.typography.secondary,
          y: chart.apx(8),
          textAnchor: "start",
          fontFamily: "sans-serif",
        }}
      />
    </>
  );
}

export default ChartAxis;
