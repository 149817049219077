import { Text, StyleSheet } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function HelperText({ text, alert }) {
  const theme = useContext(ThemeContext);
  return (
    <Text
      style={[
        styles.helpText,
        {
          color: alert ? theme.typography.alert : theme.typography.secondary,
        },
      ]}
    >
      {text}
    </Text>
  );
}

const styles = StyleSheet.create({
  helpText: {
    alignSelf: "center",
    fontSize: 12,
  },
});
export default HelperText;
