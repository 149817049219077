import React, { useState, useContext } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { ThemeContext } from "../../../../contexts/theme-context";
import { MaterialIcons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import WaveIcon from "../../../../svgs/WaveIcon";
import TideIcon from "../../../../svgs/TideIcon";
import WindIcon from "../../../../svgs/WindIcon";
import { WeatherChartContext } from "../../../../contexts/weather-context";

// Component for a single-select dropdown
const ForecastTypeSelector = ({ onSelect = () => {}, isActive = true }) => {
  const theme = useContext(ThemeContext);
  const chart = useContext(WeatherChartContext);

  const [isOpen, setIsOpen] = useState(false);

  const options = [
    {
      icon: <WaveIcon color={theme.typography.primary} />,
      title: "Wave",
    },
    {
      icon: <WindIcon color={theme.typography.primary} />,
      title: "Wind",
    },
    {
      icon: <TideIcon color={theme.typography.primary} />,
      title: "Tide",
    },
  ];

  // Handle the selection of an option
  const handleOptionSelect = (option) => {
    chart.setForecastType(option);
    setIsOpen(false);
  };

  return (
    <>
      {/* slide container */}
      <View style={[styles.container]}>
        {/* Dropdown header */}
        {!isOpen && (
          <TouchableOpacity
            onPress={() => setIsOpen(isActive ? !isOpen : isOpen)}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <View
              style={[
                styles.textContainer,
                {
                  backgroundColor: theme.buttons.selected,
                },
              ]}
            >
              <View style={styles.iconView}>{chart.forecastType.icon}</View>
            </View>
            <MaterialIcons
              name={"more-vert"}
              size={24}
              color={theme.typography.primary}
            />
          </TouchableOpacity>
        )}

        {/* Dropdown options */}
        {isOpen && (
          <View style={[styles.selectionContainer]}>
            <>
              <Entypo
                name="dot-single"
                size={22}
                color={theme.typography.primary}
              />
              {options.map((option) => (
                <TouchableOpacity
                  key={option.title}
                  onPress={() => handleOptionSelect(option)}
                  style={[
                    styles.textContainer,
                    {
                      backgroundColor:
                        option.title === chart.forecastType.title
                          ? theme.buttons.selected
                          : theme.background.default,
                    },
                  ]}
                >
                  <View style={styles.iconView}>{option.icon}</View>
                </TouchableOpacity>
              ))}
            </>
          </View>
        )}
      </View>
    </>
  );
};

// Styles for the component
const styles = StyleSheet.create({
  container: {
    width: "auto",
  },
  selectionContainer: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  textContainer: {
    height: 40,
    aspectRatio: 1,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  iconView: { width: 30, height: 30 },
  promtText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  selectionText: {
    fontSize: 16,
    fontWeight: "semi-bold",
  },
});

export default ForecastTypeSelector;
