import { ThemeContext } from "../../../../contexts/theme-context";
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../../../contexts/weather-context";
import moment from "moment";
import { shortenUnit } from "../../../../utilFunctions/unitConversionFunctions";
import { ChartContext } from "../../../../contexts/chart-context";

function GraphPanInfo() {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const weatherDetails = useContext(WeatherChartContext);
  const chart = useContext(ChartContext);
  const [panInfo, setPanInfo] = useState("");

  useEffect(() => {
    switch (weatherDetails.forecastType.title) {
      case "Wave":
        setPanInfo(
          weather.weatherForecasts[weatherDetails.spotID].get("swellHeight")
            .forecast[
            weather.timeArray[weatherDetails.selectedDay][chart.panPositionX]
          ] +
            shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get("swellHeight")
                .units
            ) +
            ", " +
            weather.weatherForecasts[weatherDetails.spotID].get("swellPeriod")
              .forecast[
              weather.timeArray[weatherDetails.selectedDay][chart.panPositionX]
            ] +
            shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get("swellPeriod")
                .units
            )
        );
        break;
      case "Wind":
        setPanInfo(
          weather.weatherForecasts[weatherDetails.spotID].get("windSustained")
            .forecast[
            weather.timeArray[weatherDetails.selectedDay][chart.panPositionX]
          ] +
            " " +
            shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get(
                "windSustained"
              ).units
            )
        );
        break;
      case "Tide":
        setPanInfo(
          weather.weatherForecasts[weatherDetails.spotID]
            .get("tideHeight")
            .forecast[
              weather.timeArray[weatherDetails.selectedDay][chart.panPositionX]
            ].toFixed(1) +
            shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get("tideHeight")
                .units
            ) +
            ", " +
            weather.weatherForecasts[weatherDetails.spotID]
              .get("tideRate")
              .forecast[
                weather.timeArray[weatherDetails.selectedDay][
                  chart.panPositionX
                ]
              ].toFixed(1) +
            shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get("tideRate")
                .units
            )
        );
        break;
      default:
    }
  }, [chart.panPositionX]);

  return (
    <View style={[styles.container]}>
      <View style={[styles.timeContainer]}>
        <Text style={[styles.timeText, { color: theme.typography.secondary }]}>
          {moment(
            weather.timeArray[weatherDetails.selectedDay][chart.panPositionX]
          ).format("h:mma")}
        </Text>
      </View>
      <View style={[styles.conditionsContainer]}>
        <Text
          style={[
            styles.conditionsText,
            {
              color: theme.typography.primary,
            },
          ]}
        >
          {panInfo}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flex: "column",
    height: 60,
    justifyContent: "space-evenly",
  },
  timeContainer: { justifyContent: "center", alignItems: "center" },
  timeText: { fontSize: 14 },
  conditionsContainer: { justifyContent: "center", alignItems: "center" },
  conditionsText: { fontSize: 20 },
});

export default GraphPanInfo;
