import { StyleSheet, View } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function SideBar(props) {
  const theme = useContext(ThemeContext);
  return (
    <View
      style={[styles.container, { backgroundColor: theme.background.default }]}
    ></View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default SideBar;
