import { ThemeContext } from "../../../../contexts/theme-context";
import { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import ForecastTypeSelector from "./ForecastTypeSelector";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../../../contexts/weather-context";
import { findMinMaxValues } from "../../../../utilFunctions/maxMinValueFunctions";
import { shortenUnit } from "../../../../utilFunctions/unitConversionFunctions";
import moment from "moment";
import GraphOverviewTide from "./GraphOverviewTide";

function GraphOverviewContainer() {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const weatherDetails = useContext(WeatherChartContext);

  const [primaryStat, setPrimaryStat] = useState("");
  const [secondStat, setSecondStat] = useState("");
  const [todaysTidePredictions, setTodaysTidePredictions] = useState([]);

  const WaveWindTextOverviewFormatting = (
    primaryWeatherType,
    secondaryWeatherType,
    secondaryPrimerText
  ) => {
    const primaryWeatherForecast =
      weather.weatherForecasts[weatherDetails.spotID].get(primaryWeatherType);
    const secondaryWeatherForecast =
      weather.weatherForecasts[weatherDetails.spotID].get(secondaryWeatherType);
    const dailyPrimaryWeatherExtremes = findMinMaxValues(
      weather.timeArray[weatherDetails.selectedDay],
      primaryWeatherForecast.forecast
    );
    const dailySecondaryWeatherValue = findMinMaxValues(
      weather.timeArray[weatherDetails.selectedDay],
      secondaryWeatherForecast.forecast
    ).max.value;

    const units = shortenUnit(primaryWeatherForecast.units);
    const primaryStatText =
      dailyPrimaryWeatherExtremes.min.value ===
      dailyPrimaryWeatherExtremes.max.value
        ? dailyPrimaryWeatherExtremes.max.value + units
        : dailyPrimaryWeatherExtremes.min.value +
          " - " +
          dailyPrimaryWeatherExtremes.max.value +
          units;
    const secondaryStatText = !dailySecondaryWeatherValue
      ? "Gusts: None"
      : secondaryPrimerText +
        dailySecondaryWeatherValue +
        shortenUnit(secondaryWeatherForecast.units);

    return { primaryStatText, secondaryStatText };
  };

  useEffect(() => {
    let primaryWeatherType, secondaryWeatherType, secondaryPrimerText;
    let primaryStatText, secondaryStatText;

    switch (weatherDetails.forecastType.title) {
      case "Wave":
        primaryWeatherType = "swellHeight";
        secondaryWeatherType = "swellPeriod";
        secondaryPrimerText = "Periods up to ";

        ({ primaryStatText, secondaryStatText } =
          WaveWindTextOverviewFormatting(
            primaryWeatherType,
            secondaryWeatherType,
            secondaryPrimerText
          ));
        setPrimaryStat(primaryStatText);
        setSecondStat(secondaryStatText);
        break;
      case "Wind":
        primaryWeatherType = "windSustained";
        secondaryWeatherType = "windGust";
        secondaryPrimerText = "Gusts up to ";

        ({ primaryStatText, secondaryStatText } =
          WaveWindTextOverviewFormatting(
            primaryWeatherType,
            secondaryWeatherType,
            secondaryPrimerText
          ));
        setPrimaryStat(primaryStatText);
        setSecondStat(secondaryStatText);
        break;
      case "Tide":
        const startTime = moment.utc(
          weather.timeArray[weatherDetails.selectedDay][0],
          "YYYY-MM-DD HH:mm"
        );
        const endTime = moment
          .utc(
            weather.timeArray[weatherDetails.selectedDay][23],
            "YYYY-MM-DD HH:mm"
          )
          .add(1, "h");

        const todaysTides = weather.weatherForecasts[weatherDetails.spotID]
          .get("tideTable")
          .forecast.predictions.filter((item) => {
            const momentTime = moment.utc(item.t, "YYYY-MM-DD HH:mm");
            return moment(momentTime).isBetween(startTime, endTime);
          });

        setPrimaryStat("");
        setSecondStat("");
        setTodaysTidePredictions(todaysTides);
        break;
      default:
        return;
    }
  }, [weatherDetails.selectedDay, weatherDetails.forecastType]);

  return (
    <View style={[styles.container]}>
      <View style={[styles.textContainer]}>
        {/* Render the secondaryStat contents as separate Text components */}
        {weatherDetails.forecastType.title === "Tide" ? (
          <GraphOverviewTide
            tidePredictions={todaysTidePredictions}
            units={shortenUnit(
              weather.weatherForecasts[weatherDetails.spotID].get("tideTable")
                .units
            )}
          />
        ) : (
          <>
            <Text
              style={[
                styles.primaryCondition,
                { color: theme.typography.primary },
              ]}
            >
              {primaryStat}
            </Text>
            <Text
              style={[
                styles.secondaryCondition,
                { color: theme.typography.secondary },
              ]}
            >
              {secondStat}
            </Text>
          </>
        )}
      </View>
      <View style={[styles.dropdownContainer]}>
        <ForecastTypeSelector />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    height: 60,
  },
  textContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  primaryCondition: { fontSize: 24 },
  secondaryCondition: { fontSize: 16 },
  dropdownContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
});

export default GraphOverviewContainer;
