import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function PageHeader({
  header,
  icon,
  subheader,
  leftButton,
  leftPress,
  rightButton,
  rightPress,
}) {
  const theme = useContext(ThemeContext);

  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerMainLine}>
        <View style={styles.buttonContainer}>
          {leftButton && (
            <TouchableOpacity onPress={leftPress}>
              {leftButton}
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.titleContainer}>
          {icon && <View style={styles.iconContainer}>{icon}</View>}
          {header && (
            <Text
              style={[styles.titleText, { color: theme.typography.primary }]}
            >
              {header}
            </Text>
          )}
        </View>
        <View style={styles.buttonContainer}>
          {rightButton && (
            <TouchableOpacity onPress={rightPress}>
              {rightButton}
            </TouchableOpacity>
          )}
        </View>
      </View>
      {subheader && (
        <View style={styles.headerSubLine}>
          <View style={styles.titleContainer}>
            <Text
              style={[
                styles.subTitleText,
                { color: theme.typography.secondary },
              ]}
            >
              {subheader}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  iconContainer: {
    margin: 6,
    width: 28,
  },
  headerMainLine: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  headerSubLine: {
    width: "70%",
    flexDirection: "row",
    alignItems: "center",
  },
  titleContainer: {
    flex: 3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: { fontSize: 32, padding: 6, textAlign: "center" },
  subTitleText: { fontSize: 18, padding: 4, textAlign: "center" },
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

export default PageHeader;
