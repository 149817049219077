import React, { useContext } from "react";
import { ThemeContext } from "../../../../contexts/theme-context";
import { View } from "react-native";
import { ChartContext } from "../../../../contexts/chart-context";

function ChartContainer({ children }) {
  const theme = useContext(ThemeContext);
  const chart = useContext(ChartContext);

  return (
    <View
      style={{
        backgroundColor: theme.background.default,
        alignItems: "stretch",
        borderWidth: 1,
        borderRadius: 10,
        borderColor: theme.divider,
        width: chart.ChartCanvasWidth,
      }}
    >
      {children}
    </View>
  );
}

export default ChartContainer;
