import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LatestActivity from "./LatestActivity";
import ProfileMain from "../profile/ProfileMain";

const Stack = createNativeStackNavigator();

function Home() {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={"LatestActivity"}
    >
      <Stack.Screen name="LatestActivity" component={LatestActivity} />
      <Stack.Screen name="OtherProfile" component={ProfileMain} />
    </Stack.Navigator>
  );
}

export default Home;
