import React, { useContext } from "react";
import { ThemeContext } from "../../../../contexts/theme-context";
import { ChartContext } from "../../../../contexts/chart-context";
import { G, Line, Circle } from "react-native-svg";

function ChartToolTip({ x, y, ticks }) {
  const theme = useContext(ThemeContext);
  const { primaryConditionData, apx, panPositionX } = useContext(ChartContext);

  if (panPositionX < 0) {
    return null;
  }

  return (
    <>
      <G x={x(panPositionX)} key="tooltip">
        <G x={x}>
          <Line
            y1={y(ticks[ticks.length - 1])}
            y2={y(ticks[0])}
            stroke={theme.divider}
            strokeWidth={apx(4)}
            /* strokeDasharray={[6, 3]} */
          />
          <Circle
            cy={y(primaryConditionData[panPositionX])}
            r={apx(10)}
            stroke={theme.background.default}
            strokeWidth={apx(1)}
            fill={theme.typography.secondary}
          />
        </G>
      </G>
    </>
  );
}

export default ChartToolTip;
