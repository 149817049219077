import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { useContext } from "react";
import { ThemeContext } from "../contexts/theme-context";
import { AntDesign } from "@expo/vector-icons";

const Snackbar = ({
  message = "Snackbar",
  timeout = 2000,
  isVisible = false,
  setIsVisible = () => {},
}) => {
  const theme = useContext(ThemeContext);
  const [screenHeight, setScreenHeight] = useState(
    Dimensions.get("window").height
  );

  useEffect(() => {
    const handleScreenResize = () => {
      setScreenHeight(Dimensions.get("window").height);
    };

    Dimensions.addEventListener("change", handleScreenResize);

    return () => {
      Dimensions.removeEventListener("change", handleScreenResize);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, timeout || 3000);

    return () => clearTimeout(timeoutId);
  }, [timeout, isVisible]);

  const handlePress = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <View
        style={[
          styles.container,
          {
            backgroundColor: theme.background.paper_e1,
            top: screenHeight - 150,
          },
        ]}
      >
        <Text style={[styles.message, { color: theme.typography.secondary }]}>
          {message}
        </Text>
        <TouchableOpacity onPress={handlePress}>
          <AntDesign
            name="close"
            size={24}
            color={theme.typography.secondary}
          />
        </TouchableOpacity>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    left: 16,
    right: 16,
  },
  message: {
    fontSize: 16,
  },
});

export default Snackbar;
