import {
  useColorScheme,
  SafeAreaView,
  StyleSheet,
  StatusBar,
} from "react-native";
import { ThemeContext, themes } from "./contexts/theme-context";
import { UserContext } from "./contexts/user-context";
import { ScreenSizeContext, GetScreenSize } from "./contexts/ScreenSizeContext";
import { useState, useEffect } from "react";
import SideBar from "./containers/SideBar";
import MainContainer from "./containers/MainContainer";
import { NavigationContainer } from "@react-navigation/native";
// SearchParams polyfill
import "react-native-url-polyfill/auto";
// Root View
import * as SystemUI from "expo-system-ui";
// User
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getUserInfo } from "./api/user";

export default function App() {
  // USER
  const [user, setUser] = useState(null);
  useEffect(() => {
    const checkUserStatus = async () => {
      const userStore = await AsyncStorage.getItem("@user");
      if (userStore) {
        const requestData = {
          queryTerms: {
            userID: userStore,
            signInFlag: false,
          },
        };
        const data = await getUserInfo(requestData);
        if (data.err) {
          console.log("Error:", data.msg);
        } else {
          setUser(data.usr);
        }
      }
    };
    checkUserStatus();
  }, []);
  // THEMEING
  const colorScheme = useColorScheme();
  const theme = colorScheme === "light" ? themes.light : themes.dark;
  useEffect(() => {
    SystemUI.setBackgroundColorAsync(theme.background.default);
  }, [theme]);
  // SCREEN FORMAT
  const screensize = GetScreenSize();

  return (
    <NavigationContainer theme={theme}>
      <UserContext.Provider value={{ user, setUser }}>
        <ThemeContext.Provider value={theme}>
          <StatusBar barStyle={theme.statusbar} />
          <ScreenSizeContext.Provider value={screensize}>
            <SafeAreaView
              style={[
                styles.safeViewArea,
                {
                  backgroundColor: theme.colors.background,
                },
              ]}
            >
              {screensize.size !== "xs" && <SideBar />}

              <MainContainer />
              {screensize.size !== "xs" && <SideBar />}
            </SafeAreaView>
          </ScreenSizeContext.Provider>
        </ThemeContext.Provider>
      </UserContext.Provider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  safeViewArea: {
    flexDirection: "row",
    flex: 1,
    overflow: "hidden",
  },
});
