import {
  FlatList,
  View,
  ActivityIndicator,
  Text,
  RefreshControl,
} from "react-native";
import Post3 from "../post/Post";
import { useState, useContext, useEffect } from "react";
import { getSessions } from "../api/sessions";
import { ThemeContext } from "../contexts/theme-context";
import { RouteContext } from "../contexts/route-context";

function UserActivity({ activityQuery, setPostRemoved }) {
  const theme = useContext(ThemeContext);
  const { feedRerender, setFeedRerender } = useContext(RouteContext);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allLoaded, setAllLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const fetchCnt = 10;

  useEffect(() => {
    setIsLoading(true);
    setAllLoaded(false);
    setPosts([]);
    fetchPosts(0);
    if (feedRerender) {
      setFeedRerender(false);
    }
  }, [feedRerender, activityQuery]);

  const onRefresh = async () => {
    setRefreshing(true);
    setPosts([]);
    setAllLoaded(false);
    fetchPosts(0);
    setRefreshing(false);
  };

  const removePost = async (postID) => {
    const filteredPosts = await posts.filter((post) => {
      return post._id !== postID;
    });
    setPosts(filteredPosts);
    setPostRemoved(true);
  };

  const fetchPosts = async (fetchOffset) => {
    const requestData = {
      queryTerms: activityQuery,
      options: { fetchOffset: fetchOffset, fetchCnt: fetchCnt },
    };
    const data = await getSessions(requestData);
    if (data.length < fetchCnt) {
      setAllLoaded(true);
    }
    // for first load
    if (!fetchOffset) {
      setPosts([...data]);
    } else {
      setPosts([...posts, ...data]);
    }
    setIsLoading(false);
  };

  const handleEndReached = async () => {
    if (!isLoading && !allLoaded) {
      setIsLoading(true);
      fetchPosts(posts.length);
    }
  };

  const renderHeader = () => {
    if (!isLoading) return null;
    else if (!posts.length) {
      return (
        <View style={{ paddingVertical: 80 }}>
          <ActivityIndicator size="large" color={theme.typography.primary} />
        </View>
      );
    }
  };

  const renderFooter = () => {
    if (allLoaded && !posts.length)
      return (
        <View
          style={{
            paddingVertical: 20,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ color: theme.typography.primary }}>
            No recent activity.
          </Text>
        </View>
      );
    else if (allLoaded) {
      return null;
    } else if (!isLoading) return null;
    else if (posts.length) {
      return (
        <View style={{ paddingVertical: 20 }}>
          <ActivityIndicator size="large" color={theme.typography.primary} />
        </View>
      );
    }
  };

  return (
    <FlatList
      data={posts}
      renderItem={({ item }) => {
        return <Post3 postData={item} removePost={removePost} />;
      }}
      keyExtractor={(item) => item._id}
      showsVerticalScrollIndicator={false}
      onEndReached={handleEndReached}
      onEndReachedThreshold={0.1}
      ListFooterComponent={renderFooter}
      ListHeaderComponent={renderHeader}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
    />
  );
}

export default UserActivity;
