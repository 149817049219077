import { useContext } from "react";
import { ThemeContext } from "../../../../contexts/theme-context";
import { ChartContext } from "../../../../contexts/chart-context";
import { Path } from "react-native-svg";

export const CustomLine1 = ({ line }) => {
  const theme = useContext(ThemeContext);
  const { apx } = useContext(ChartContext);

  return (
    <Path
      key="line"
      d={line}
      stroke={theme.accentColors.theme}
      strokeWidth={apx(6)}
      fill="none"
    />
  );
};

export const CustomLine2 = ({ line }) => {
  const theme = useContext(ThemeContext);
  const { apx } = useContext(ChartContext);

  return (
    <Path
      key="line"
      d={line}
      stroke={theme.typography.disabled}
      strokeWidth={apx(6)}
      fill="none"
    />
  );
};
