import { View, StyleSheet, Text } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";
import {
  WeatherContext,
  WeatherChartContext,
} from "../contexts/weather-context";

function LocationBio() {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);
  const chart = useContext(WeatherChartContext);

  const spotWeather = weather.weatherForecasts[chart.spotID];

  return (
    <View style={[styles.contentContainer, { borderColor: theme.divider }]}>
      <View style={[styles.titleContainer, { borderColor: theme.divider }]}>
        <Text style={[styles.titleText, { color: theme.typography.primary }]}>
          Information
        </Text>
      </View>
      <View style={[styles.infoContainer]}>
        <View style={[styles.info]}>
          {spotWeather.get("poiInfo").location.state && (
            <Text
              style={[styles.infoText, { color: theme.typography.primary }]}
            >
              State: {spotWeather.get("poiInfo").location.state}
            </Text>
          )}
        </View>
        <View style={[styles.info]}>
          <Text style={[styles.infoText, { color: theme.typography.primary }]}>
            Region: {spotWeather.get("poiInfo").location.region}
          </Text>
        </View>
        <View style={[styles.info]}>
          <Text style={[styles.infoText, { color: theme.typography.primary }]}>
            Country: {spotWeather.get("poiInfo").location.country}
          </Text>
        </View>
        <View style={[styles.info]}>
          <Text style={[styles.infoText, { color: theme.typography.primary }]}>
            Coordinates: lat:{" "}
            {spotWeather.get("poiInfo").location.coordinates.latitude}, lon:{" "}
            {spotWeather.get("poiInfo").location.coordinates.longitude}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    width: "90%",
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 20,
    padding: 10,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
  },
  titleText: { fontSize: 16, paddingBottom: 10 },
  infoContainer: {
    flexDirection: "column",
  },
  info: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  infoText: { fontSize: 16, paddingTop: 10, paddingBottom: 10 },
});

export default LocationBio;
