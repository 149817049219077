export function findMinMaxValues(keys, object) {
  let values = keys.map((key) => parseInt(object[key]));

  // filter nulls and return null if array contains only nulls
  values = values.filter((num) => !isNaN(num));
  if (!values.length)
    return { max: { key: null, value: null }, min: { key: null, value: null } };

  let max = Math.max(...values);
  let min = Math.min(...values);

  let maxKey = keys[values.indexOf(max)];
  let minKey = keys[values.indexOf(min)];

  return { max: { key: maxKey, value: max }, min: { key: minKey, value: min } };
}

// Utility function to calculate extreme values
export const calculateDailyExtremes = (timeArray, forecasts) => {
  return timeArray.map((dailyTimeArray) => {
    return findMinMaxValues(dailyTimeArray, forecasts);
  });
};

// Function to get daily extremes for a given property
export const getDailyExtremes = (property, weather, spotID) => {
  return calculateDailyExtremes(
    weather.timeArray,
    weather.weatherForecasts[spotID]?.get(property).forecast || []
  );
};

export const calculateWeeklyWindExtremes = (
  sustainedExtremes,
  gustExtremes
) => {
  const maxSustainedValues = sustainedExtremes.map((item) => item.max.value);
  const maxGustValues = gustExtremes.map((item) => item.max.value);

  return {
    max: Math.max(...maxSustainedValues, ...maxGustValues),
    min: Math.min(...sustainedExtremes.map((item) => item.min.value)),
  };
};

export const calculateWeeklySwellExtremes = (swellExtremes) => {
  const maxSwellValues = swellExtremes.map((item) => item.max.value);

  return {
    max: Math.max(...maxSwellValues),
    min: Math.min(...swellExtremes.map((item) => item.min.value)),
  };
};
