import React, { useContext } from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { ThemeContext } from "../../contexts/theme-context";
import { UserContext } from "../../contexts/user-context";
import {
  WeatherChartContext,
  WeatherContext,
} from "../../contexts/weather-context";
import PageHeader from "../../containers/PageHeader";
import { putUserInfo } from "../../api/user";
import AsyncStorage from "@react-native-async-storage/async-storage";

function SpotDetailsPageHeader({
  name,
  region,
  spotSaved,
  setSpotSaved,
  navigation,
  setSnackBarMsg,
  setShowSnackbar,
}) {
  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { weatherSpots, setWeatherSpots } = useContext(WeatherContext);
  const { spotID } = useContext(WeatherChartContext);

  const handleFavSpotChange = async (
    user,
    setSnackBarMsg,
    setShowSnackbar,
    setSpotSaved,
    weatherSpots,
    spotSaved,
    spotID
  ) => {
    const isUserLoggedIn = !!user;
    const saveMessage = spotSaved ? "Location Removed!" : "Location Saved!";

    try {
      const updateSettings = async (settings) => {
        const response = await putUserInfo({
          userID: user.userID,
          settings: { ...settings },
        });
        if (response.modifiedCount) {
          setSpotSaved(!spotSaved);
        } else {
          throw new Error("Oops something went wrong!");
        }
      };

      const toggleSpotSaved = async () => {
        const newWeatherSpots = spotSaved
          ? weatherSpots.filter((item) => item !== spotID)
          : weatherSpots;

        if (isUserLoggedIn) {
          const settings = { ...user.settings, favoriteSpots: newWeatherSpots };
          await updateSettings(settings);
        } else {
          await AsyncStorage.setItem(
            "@favoriteSpots",
            JSON.stringify(newWeatherSpots)
          );
          setSpotSaved(!spotSaved);
        }
      };

      await toggleSpotSaved();
      setSnackBarMsg(saveMessage);
      setShowSnackbar(true);
    } catch (error) {
      setSnackBarMsg("Oops something went wrong!");
      setShowSnackbar(true);
    }
  };

  return (
    <PageHeader
      header={name}
      subheader={region}
      leftButton={
        <AntDesign name="back" size={30} color={theme.typography.primary} />
      }
      leftPress={() => {
        if (!spotSaved) {
          setWeatherSpots(weatherSpots.filter((item) => item !== spotID));
          navigation.goBack();
        } else {
          navigation.navigate("Weather Overview");
        }
      }}
      rightButton={
        <Ionicons
          name={spotSaved ? "checkmark-circle-outline" : "add-circle-outline"}
          size={30}
          color={theme.typography.primary}
        />
      }
      rightPress={() => {
        handleFavSpotChange(
          user,
          setSnackBarMsg,
          setShowSnackbar,
          setSpotSaved,
          weatherSpots,
          spotSaved,
          spotID
        );
      }}
    />
  );
}

export default SpotDetailsPageHeader;
