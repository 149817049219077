import React, { useContext } from "react";
import {
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  View,
  Text,
} from "react-native";
import CompassArrowTextCenter from "../svgs/svgComponents/CompassArrowTextCenter";
import WeatherArrow from "../svgs/svgComponents/WeatherArrow";
import { ThemeContext } from "../contexts/theme-context";
import { WeatherContext } from "../contexts/weather-context";
import { shortenUnit } from "../utilFunctions/unitConversionFunctions";
import { reverseCompassDirection } from "../utilFunctions/directionConversionFunctions";

function SpotOverview({ navigation, spotID }) {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);

  const spotWeather = weather.weatherForecasts[spotID];

  const navigateToSpotDetails = () => {
    if (spotWeather) {
      navigation.navigate("SpotDetails", {
        spotID: spotID,
        name: spotWeather.get("poiInfo").name,
        region: spotWeather.get("poiInfo").location.region,
        saved: true,
      });
    }
  };

  return (
    <TouchableOpacity
      style={{
        ...styles.contentContainer,
        borderColor: theme.divider,
        backgroundColor: theme.background.paper_e1,
      }}
      activeOpacity={0.8}
      onPress={navigateToSpotDetails}
    >
      {spotWeather ? (
        <>
          <View style={styles.titleContainer}>
            <Text
              style={{ ...styles.titleText, color: theme.typography.primary }}
            >
              {spotWeather.get("poiInfo").name ?? "blur"}
            </Text>
          </View>
          <View style={styles.weatherContainer}>
            <View style={styles.waveContainer}>
              <CompassArrowTextCenter
                color={theme.accentColors.theme}
                text={`${
                  spotWeather.get("swellHeight").forecast[weather.currentHour]
                }${shortenUnit(spotWeather.get("swellHeight").units)}`}
                textColor={theme.typography.primary}
                rotation={reverseCompassDirection(
                  parseInt(
                    spotWeather.get("swellDirection").forecast[
                      weather.currentHour
                    ]
                  )
                )}
              />
              <Text
                style={{
                  ...styles.conditionsTextContainer,
                  color: theme.typography.secondary,
                }}
              >
                <Text style={styles.conditionsText}>
                  {spotWeather.get("swellPeriod").forecast[weather.currentHour]}
                </Text>
                <Text style={styles.conditionsLabelText}>
                  {shortenUnit(spotWeather.get("swellPeriod").units)}
                </Text>
              </Text>
            </View>
            <View style={styles.windContainer}>
              <Text
                style={{
                  ...styles.conditionsTextContainer,
                  color: theme.typography.primary,
                }}
              >
                <Text style={styles.conditionsText}>
                  {
                    spotWeather.get("windSustained").forecast[
                      weather.currentHour
                    ]
                  }
                </Text>
                <Text style={styles.conditionsLabelText}>
                  {shortenUnit(spotWeather.get("windSustained").units)}
                </Text>
              </Text>
              {spotWeather.get("windGust").forecast[weather.currentHour] && (
                <Text
                  style={{
                    ...styles.conditionsTextContainer,
                    color: theme.typography.secondary,
                  }}
                >
                  <Text style={styles.conditionsText}>
                    {spotWeather.get("windGust").forecast[weather.currentHour]}
                  </Text>
                  <Text style={styles.conditionsLabelText}>
                    {shortenUnit(spotWeather.get("windGust").units)}
                  </Text>
                </Text>
              )}
              <WeatherArrow
                chartWidth={35}
                color={theme.accentColors.theme}
                rotation={reverseCompassDirection(
                  parseInt(
                    spotWeather.get("windDirection").forecast[
                      weather.currentHour
                    ]
                  )
                )}
              />
            </View>
          </View>
        </>
      ) : (
        <View style={styles.spinnerContainer}>
          <ActivityIndicator size="large" color={theme.typography.primary} />
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
    borderRadius: 10,
    //borderWidth: 1,
    width: "90%",
    paddingHorizontal: 10,
    paddingVertical: 2,
    height: 94,
    marginBottom: 20,
  },
  titleContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    paddingVertical: 8,
  },
  titleText: { fontSize: 24 },
  weatherContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  weatherText: { fontSize: 18 },
  waveContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  windContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  conditionsTextContainer: { paddingHorizontal: 4 },
  conditionsText: { fontSize: 20 },
  conditionsLabelText: { fontSize: 14 },
  spinnerContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

export default SpotOverview;
