// Import necessary dependencies and components
import React, { useContext, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import moment from "moment";

import SoloButton from "../uiCommon/SoloButton";
import TimeSelection from "./sessionUIcomponents/TimeSelection";
import HelperText from "../uiCommon/HelperText";
import TwoButtonSelection from "./sessionUIcomponents/TwoButtonSelection";
import PageHeader from "../containers/PageHeader";
import LeaveSessionModal from "./sessionUIcomponents/LeaveSessionModal";
import DateSelector from "../uiCommon/dateSelector/DateSelector";

import { ThemeContext } from "../contexts/theme-context";

// Define the button titles
const buttonTitles = ["Session", "Surf Check"];

// Define the NewSessionTiming component
const NewSessionTiming = ({ navigation, route }) => {
  // Get the theme from the context
  const theme = useContext(ThemeContext);

  // Define state variables
  const [showExitModal, setShowExitModal] = useState(false);
  const [alertText, setAlertText] = useState(" ");
  const [reportType, setReportType] = useState({
    helperText: "Select Report Type",
    val: null,
  });
  const [startTime, setStartTime] = useState({
    helperText: "Select Start Time",
    val: null,
  });
  const [endTime, setEndTime] = useState({
    helperText: "Select End Time",
    val: null,
  });
  const [selectedDate, setSelectedDate] = useState(0);

  // Function to handle end time selection
  const endTimeCheck = (time) => {
    if (time >= startTime.val) {
      setEndTime({
        val: time,
        helperText: "End Time: " + moment(time, "H").format("h A"),
      });
      setAlertText(" ");
    } else {
      setAlertText("End time must be greater than or equal to start time.");
    }
  };

  // Function to handle start time selection
  const startTimeCheck = (time) => {
    if (time <= endTime.val || endTime.val === null) {
      setStartTime({
        val: time,
        helperText: "Start Time: " + moment(time, "H").format("h A"),
      });
      setAlertText(" ");
    } else {
      setAlertText("Start time must be less than or equal to end time.");
    }
  };

  // Function to handle report type selection
  const reportTypeCheck = (type) => {
    if (type === "Surf Check") {
      setEndTime({
        helperText: "Select End Time",
        val: null,
      });
    }
    setReportType({
      val: type,
      helperText: " ",
    });
    setAlertText("");
  };

  // Function to handle continue button press
  const handleContinue = () => {
    if (reportType.val === null) {
      setAlertText("Select Report Type");
    } else if (reportType.val === "Surf Check" && startTime.val === null) {
      setAlertText("Select Start Time");
    } else if (
      reportType.val === "Session" &&
      (startTime.val === null || endTime.val === null)
    ) {
      if (startTime.val === null) {
        setAlertText("Select Start Time");
      } else {
        setAlertText("Select End Time");
      }
    } else {
      navigation.navigate("Session Rating", {
        ...route.params,
        timing: {
          reportType: reportType.val,
          startTime: moment()
            .startOf("day")
            .add(startTime.val, "h")
            .subtract(selectedDate, "d")
            .utc()
            .format("YYYY-MM-DDTHH:00:00[Z]"),
          endTime: endTime.val
            ? moment()
                .startOf("day")
                .add(endTime.val, "h")
                .subtract(selectedDate, "d")
                .utc()
                .format("YYYY-MM-DDTHH:00:00[Z]")
            : endTime.val,
        },
      });
    }
  };

  // Render the component
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background.default,
          borderColor: theme.divider,
        },
      ]}
    >
      {showExitModal && <LeaveSessionModal handleCancel={setShowExitModal} />}
      {/* Render the page header */}
      <PageHeader
        header="New Session"
        subheader="Select Session Type, Date and Time or Timeframe"
        leftButton={
          <AntDesign name="back" size={30} color={theme.typography.primary} />
        }
        leftPress={navigation.goBack}
        rightButton={
          <Ionicons
            name="ios-trash-outline"
            size={30}
            color={theme.typography.primary}
          />
        }
        rightPress={() => {
          setShowExitModal(true);
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}
      >
        {/* Render the two-button selection component */}
        <TwoButtonSelection
          selected={reportType.val}
          setSelected={reportTypeCheck}
          buttonTitles={buttonTitles}
        />

        <DateSelector
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          inverted={true}
        />

        {/* Render the start time selection */}

        <TimeSelection time={startTime.val} setTime={startTimeCheck} />
        <HelperText text={startTime.helperText} />

        {/* Render the end time selection */}
        {reportType.val === "Session" && (
          <>
            <TimeSelection time={endTime.val} setTime={endTimeCheck} />
            <HelperText text={endTime.helperText} />
          </>
        )}

        {/* Render the alert text */}
        <HelperText text={alertText} alert />
        {/* Placeholder view to create space for buttons */}
        <View style={styles.buttonPlaceholder}></View>
      </ScrollView>

      {/* Render the continue button */}
      <SoloButton submit={handleContinue} title="CONTINUE" />
    </View>
  );
};

// Define the component's styles
const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    flex: 1,
  },
  scrollView: {
    width: "100%",
  },
  buttonPlaceholder: {
    height: 75,
  },
});

export default NewSessionTiming;
