import { View, Text, StyleSheet } from "react-native";
import PageHeader from "../containers/PageHeader";
import SpotOverview from "./SpotOverview";
import { AntDesign } from "@expo/vector-icons";
import { ThemeContext } from "../contexts/theme-context";
import { useContext, useEffect } from "react";
import { WeatherContext } from "../contexts/weather-context";

function WeatherOverview({ navigation }) {
  const theme = useContext(ThemeContext);
  const weather = useContext(WeatherContext);

  return (
    <View style={styles.contentContainer}>
      <PageHeader
        header={"Weather"}
        rightButton={
          <AntDesign
            name="search1"
            size={30}
            color={theme.typography.primary}
          />
        }
        rightPress={() => {
          navigation.navigate("Location Search");
        }}
      />
      {weather.weatherSpots.length ? (
        <WeatherContainers navigation={navigation} />
      ) : (
        <Text
          style={{
            color: theme.typography.primary,
            alignSelf: "center",
            margin: 40,
            textAlign: "center",
          }}
        >
          Tap the search icon to find surf spots. After you have found the spot
          you're looking for, tap add in the upper right corner to find your
          favorite locations easier next time!
        </Text>
      )}
    </View>
  );
}

const WeatherContainers = ({ navigation }) => {
  const weather = useContext(WeatherContext);
  return weather.weatherSpots.map((spotID) => {
    return (
      <SpotOverview navigation={navigation} spotID={spotID} key={spotID} />
    );
  });
};

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});

export default WeatherOverview;
