import React, { useState, useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  TouchableWithoutFeedback,
  StyleSheet,
} from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { MaterialIcons } from "@expo/vector-icons";

// Component for a single-select dropdown
const SingleSelectDropdown = ({
  options = { item: null, key: null },
  onSelect = () => {},
  selected = null,
  prompt = "",
  isActive = true,
}) => {
  const theme = useContext(ThemeContext);

  const [isOpen, setIsOpen] = useState(false);

  // Handle the selection of an option
  const handleOptionSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  // Handle click outside the dropdown to close it
  const handleClickOutside = () => {
    setIsOpen(false);
  };

  // Calculate the maximum height of the dropdown
  const windowHeight = Dimensions.get("window").height * 0.5;
  const dropdownHeight = options.length * 50; // Assuming each option is 50px in height
  const maxHeight =
    dropdownHeight > windowHeight ? windowHeight - 100 : dropdownHeight;

  return (
    <>
      {/* Overlay to capture click outside the dropdown */}
      {isOpen && (
        <TouchableWithoutFeedback onPress={handleClickOutside}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          ></View>
        </TouchableWithoutFeedback>
      )}
      {/* Dropdown container */}
      <View
        style={[
          styles.container,
          {
            backgroundColor: theme.background.primary,
            borderColor: theme.divider,
          },
        ]}
      >
        {/* Dropdown header */}
        <TouchableOpacity
          onPress={() => setIsOpen(isActive ? !isOpen : isOpen)}
          style={[
            styles.textContainer,
            { borderBottomWidth: isOpen ? 2 : 0, borderColor: theme.divider },
          ]}
        >
          <Text style={[styles.promtText, { color: theme.typography.primary }]}>
            {selected === null ? prompt : selected.item}
          </Text>

          <MaterialIcons
            name={isOpen ? "keyboard-arrow-down" : "keyboard-arrow-right"}
            size={24}
            color={theme.typography.primary}
          />
        </TouchableOpacity>

        {/* Dropdown options */}
        {isOpen && (
          <ScrollView style={{ maxHeight }}>
            {options.map((option) => (
              <TouchableOpacity
                key={option.key}
                onPress={() => handleOptionSelect(option)}
                style={[styles.textContainer]}
              >
                <Text
                  style={[
                    styles.selectionText,
                    { color: theme.typography.primary },
                  ]}
                >
                  {option.item}
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        )}
      </View>
    </>
  );
};

// Styles for the component
const styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 2,
    borderWidth: 1,
  },
  textContainer: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    height: 46,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  promtText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  selectionText: {
    fontSize: 16,
    fontWeight: "semi-bold",
  },
});

export default SingleSelectDropdown;
