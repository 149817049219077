import React, { useEffect, useState, createContext } from "react";
import { Dimensions } from "react-native";

const windowDimensions = Dimensions.get("window");

export function GetScreenSize() {
  const [screenSize, setScreenSize] = useState({
    width: windowDimensions.width,
    height: windowDimensions.height,
    size: ScreenSizer(windowDimensions.width),
  });
  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({ window }) => {
      setScreenSize({ width: window.width, size: ScreenSizer(window.width) });
    });
    return () => subscription?.remove();
  });

  return screenSize;
}

export const ScreenSizeContext = createContext();

export const ScreenSizes = {
  xs: 550,
  sm: 900,
  md: 1200,
  lg: 1536,
};

const ScreenSizer = (px) => {
  switch (true) {
    case px < ScreenSizes.xs:
      return "xs";
    case px < ScreenSizes.sm:
      return "sm";
    case px < ScreenSizes.md:
      return "md";
    case px < ScreenSizes.lg:
      return "lg";
    default:
      return "xl";
  }
};
