import { StyleSheet, View, Text } from "react-native";
// THEMEING
import { ThemeContext } from "../../contexts/theme-context";
import { useContext, useState } from "react";
import PostTagBubble from "../../post/PostTagBubble";
import NewSessionRatingButtons from "./NewSessionRatingButtons";
import tagGrab from "../assets/tags";
import ratingTypeConstants from "../assets/ratingTypeConstants";

function NewSessionRatingInput({
  conditionType,
  feedbackObject,
  alterFeedback,
}) {
  const theme = useContext(ThemeContext);
  /* const [selectedTags, setSelectedTags] = useState({
    tags: feedbackObject["tags"],
  });
  const [rating, setRating] = useState(feedbackObject["rating"]); */

  const selectedTagTest = (tag) => {
    feedbackObject.tags.includes(tag)
      ? alterFeedback(
          conditionType,
          "tags",
          feedbackObject.tags.filter((val) => {
            return val !== tag;
          })
        )
      : alterFeedback(conditionType, "tags", [...feedbackObject.tags, tag]);
    /*  setSelectedTags({ tags: tags }); */
  };

  const setRating = (rating) => {
    alterFeedback(conditionType, "rating", rating);
  };

  const conditionConstants = ratingTypeConstants(conditionType);

  return (
    <View style={[styles.ratingContainer]}>
      <View style={[styles.titleContainer]}>
        <View style={[styles.iconContainer]}>{conditionConstants.icon}</View>
        <Text style={[styles.titleText, { color: theme.typography.primary }]}>
          {conditionConstants.title}
        </Text>
      </View>
      <NewSessionRatingButtons
        highLabel={conditionConstants.highLabel}
        lowLabel={conditionConstants.lowLabel}
        rating={feedbackObject.rating}
        setRating={setRating}
      />
      <View style={styles.tagContainer}>
        <>
          <Text
            style={[styles.tagTitle, { color: theme.typography.secondary }]}
          >
            Tags:
          </Text>
          {feedbackObject.tags.map((tag, idx) => {
            // Selected tags upfront
            return (
              <PostTagBubble
                key={idx}
                tag={tag}
                onPress={selectedTagTest}
                selected={feedbackObject.tags.includes(tag)}
              />
            );
          })}
          {tagGrab(conditionType, feedbackObject.rating) // filter selected tags out from suggested
            .filter((ratingTag) => !feedbackObject.tags.includes(ratingTag))
            .map((tag, idx) => {
              return (
                <PostTagBubble
                  key={idx}
                  tag={tag}
                  onPress={selectedTagTest}
                  selected={feedbackObject.tags.includes(tag)}
                />
              );
            })}
        </>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  ratingContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: 20,
  },
  titleContainer: {
    marginBottom: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  titleText: { fontSize: 20, fontWeight: "bold" },
  iconContainer: { width: 20, height: 20, marginRight: 4, marginLeft: 4 },
  tagContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    alignContent: "flex-start",
    marginTop: 8,
    marginHorizontal: 4,
  },
  tagTitle: {
    fontSize: 18,
  },
});

export default NewSessionRatingInput;
