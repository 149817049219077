import { View, StyleSheet, Text } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";
import CompassLogoEmptyCenter from "../../svgs/svgComponents/CompassArrowTextCenter";
import WeatherArrow from "../../svgs/svgComponents/WeatherArrow";

function HourlyForecastOverview({ data }) {
  const theme = useContext(ThemeContext);

  return (
    <View style={styles.contentContainer}>
      <View style={styles.timeContainer}>
        <Text style={styles.timeTextContainer}>
          <Text style={[styles.timeText, { color: theme.typography.primary }]}>
            {data.time.time}
          </Text>
          <Text style={[styles.labelText, { color: theme.typography.primary }]}>
            {data.time.label}
          </Text>
        </Text>
      </View>
      <View
        style={[styles.waveContainer, { borderBottomColor: theme.divider }]}
      >
        <CompassLogoEmptyCenter
          color={theme.accentColors.theme}
          text={data.wave.height + "'"}
          textColor={theme.typography.primary}
          rotation={data.wave.direction}
          /* chartWidth={50} */
        />
        <Text style={[styles.waveText]}>
          <Text
            style={[styles.timeText, { color: theme.typography.secondary }]}
          >
            {data.wave.period.value}
          </Text>
          <Text
            style={[styles.labelText, { color: theme.typography.secondary }]}
          >
            {data.wave.period.units}
          </Text>
        </Text>
      </View>
      <View style={styles.windContainer}>
        <WeatherArrow
          chartWidth={30}
          color={theme.accentColors.theme}
          rotation={data.wind.direction}
        />
        <Text>
          <Text
            style={[styles.timeText, { color: theme.typography.secondary }]}
          >
            {data.wind.sustained.value}
          </Text>
          <Text
            style={[styles.labelText, { color: theme.typography.secondary }]}
          >
            {data.wind.sustained.units}
          </Text>
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginLeft: 6,
    marginRight: 6,
    height: 170,
  },
  timeContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
  },
  timeTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  timeText: {
    fontSize: 16,
  },
  labelText: { fontSize: 10 },
  waveContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 80,
    borderBottomWidth: 1,
  },
  waveText: {},
  windContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 60,
  },
});

export default HourlyForecastOverview;
