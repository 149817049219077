import { StyleSheet, View } from "react-native";
import { React, useState, useEffect, useContext } from "react";
import ProfileHeader from "./ProfileHeader";
import NavigationHeader from "../containers/NavigationHeader";
import UserActivity from "./UserActivity";
import UserAnalytics from "./UserAnalytics";
// Auth
import AuthStack from "../authorization/AuthStack";
import { UserContext } from "../contexts/user-context";
import { ThemeContext } from "../contexts/theme-context";
import PageHeader from "../containers/PageHeader";
import { Ionicons } from "@expo/vector-icons";

const Profile = ({ navigation, route }) => {
  const [activePath, setActivePath] = useState("Activity");
  /* const paths = ["Activity", "ANALYTICS"]; */
  const paths = ["Activity"];
  const [userID, setUserID] = useState(null);
  const [screenName, setScreenName] = useState(null);
  const [usersProfile, setUsersProfile] = useState(false);
  const [postRemoved, setPostRemoved] = useState(false);

  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (route.name === "UserProfile" && user) {
      setUserID(user.userID);
      setScreenName(user.settings.screenName);
      setUsersProfile(true);
      setActivePath("Activity");
    } else if (route.name === "OtherProfile") {
      setUserID(route.params.userID);
      setScreenName(route.params.screenName);
      setUsersProfile(false);
      setActivePath("Activity");
    } else {
      setUserID(null);
      setScreenName(null);
      setUsersProfile(false);
      setActivePath("Activity");
    }
  }, [route, user]);

  useEffect(() => {
    navigation.setOptions({
      title: screenName ? screenName : "Welcome!",
    });
  }, [screenName]);

  return (
    <>
      {route.name === "UserProfile" && <AuthStack />}
      {((route.name === "UserProfile" && user) ||
        route.name === "OtherProfile") && (
        <View style={styles.container}>
          <PageHeader
            rightButton={
              usersProfile ? (
                <Ionicons
                  name="settings-outline"
                  size={30}
                  color={theme.typography.primary}
                />
              ) : (
                <></>
              )
            }
            rightPress={() => {
              if (usersProfile) {
                navigation.navigate("ProfileSettings");
              }
            }}
          />
          <ProfileHeader
            screenName={screenName}
            userID={userID}
            postRemoved={postRemoved}
            setPostRemoved={setPostRemoved}
          />
          <NavigationHeader
            paths={paths}
            activePath={activePath}
            setActivePath={setActivePath}
          />
          {activePath === "Activity" &&
            (userID ? (
              <UserActivity
                activityQuery={{ userID: userID }}
                setPostRemoved={setPostRemoved}
              />
            ) : (
              <></>
            ))}
          {activePath === "ANALYTICS" && <UserAnalytics />}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Profile;
