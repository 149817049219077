import { StyleSheet, TouchableHighlight, View, Text } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext, useMemo } from "react";
import moment from "moment";

const DateSelectorButton = ({ selected, index, onSelect, inverted }) => {
  const theme = useContext(ThemeContext);

  const formattedDay = useMemo(
    () =>
      inverted
        ? moment().subtract(index, "day").format("dd")
        : moment().add(index, "day").format("dd"),
    [index]
  );

  const formattedDate = useMemo(
    () =>
      inverted
        ? moment().subtract(index, "day").format("D")
        : moment().add(index, "day").format("D"),
    [index]
  );

  return (
    <View style={[styles.buttonContainer]}>
      <Text style={[styles.buttonText, { color: theme.typography.secondary }]}>
        {formattedDay}
      </Text>
      <TouchableHighlight
        style={[
          styles.button,
          {
            backgroundColor:
              selected === index
                ? theme.buttons.selected
                : theme.background.default,
          },
        ]}
        onPress={() => {
          onSelect(index);
        }}
      >
        <Text
          style={[styles.buttonText, { color: theme.typography.secondary }]}
        >
          {formattedDate}
        </Text>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    width: 40,
    aspectRatio: 1,
    borderRadius: 25,
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  buttonText: { fontSize: 16 },
});

export default DateSelectorButton;
