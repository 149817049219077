import React, { useContext } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { AntDesign } from "@expo/vector-icons";

import { ThemeContext } from "../../contexts/theme-context";
import PageHeader from "../../containers/PageHeader";
import WeatherDetailsDateSelector from "./charts/dateSelector/WeatherDetailsDateSelector";
import WeatherDetailsChart from "./charts/WeatherDetailsChart";
import { WeatherChartContext } from "../../contexts/weather-context";
import FullScreenDrawer from "../../uiCommon/FullScreenDrawer";
import GestureRecognizer from "react-native-swipe-gestures";

function WeatherDetailsPopUp() {
  const theme = useContext(ThemeContext);
  const weatherDetails = useContext(WeatherChartContext);

  return (
    <FullScreenDrawer
      open={weatherDetails.selectedDay > -1}
      onClose={() => weatherDetails.setSelectedDay(-1)}
    >
      <GestureRecognizer
        onSwipeDown={() => weatherDetails.setSelectedDay(-1)}
        onSwipeLeft={() => {
          if (weatherDetails.selectedDay < 5 && !weatherDetails.activeTooltip) {
            weatherDetails.setSelectedDay(weatherDetails.selectedDay + 1);
          }
        }}
        onSwipeRight={() => {
          if (weatherDetails.selectedDay > 0 && !weatherDetails.activeTooltip) {
            weatherDetails.setSelectedDay(weatherDetails.selectedDay - 1);
          }
        }}
        style={styles.gestContainer}
      >
        <PageHeader
          header={weatherDetails.forecastType.title}
          icon={weatherDetails.forecastType.icon}
          rightButton={
            <AntDesign
              name="closecircle"
              size={24}
              color={theme.typography.disabled}
            />
          }
          rightPress={() => weatherDetails.setSelectedDay(-1)}
        />
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.chartContainer}>
            <WeatherDetailsDateSelector />
            <WeatherDetailsChart />
          </View>
        </ScrollView>
      </GestureRecognizer>
    </FullScreenDrawer>
  );
}

const styles = StyleSheet.create({
  chartContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  gestContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default WeatherDetailsPopUp;
