import { View, StyleSheet } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext, useState } from "react";
import Avatar from "../uiCommon/Avatar";
import Post3TitleContainer from "./PostTitleContainer";
import Post3RatingContainer from "./PostRatingContainer";
import Post3InteractionContainer from "./PostInteractionContainer";
import { PostContext } from "../contexts/post-context";

function Post({ postData, removePost }) {
  const [expand, setExpand] = useState(false);
  const content = postData;
  const theme = useContext(ThemeContext);

  return (
    <PostContext.Provider value={content}>
      <View style={[styles.postContainer, { borderColor: theme.divider }]}>
        <Avatar user={postData.userName} size={48} />
        <View style={[styles.contentContainer, {}]}>
          <Post3TitleContainer />
          <View style={[styles.infoContainer]}>
            <Post3RatingContainer expand={expand} type={"Wave"} />
            <Post3RatingContainer expand={expand} type={"Wind"} />
            <Post3RatingContainer expand={expand} type={"Tide"} />
            <Post3RatingContainer expand={expand} type={"Crowd"} />
          </View>
          <Post3InteractionContainer
            expand={expand}
            setExpand={setExpand}
            postID={postData._id}
            removePost={removePost}
          />
        </View>
      </View>
    </PostContext.Provider>
  );
}

const styles = StyleSheet.create({
  postContainer: {
    padding: 10,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  contentContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 10,
    flex: 1,
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
});

export default Post;
