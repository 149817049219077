import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { AreaChart } from "react-native-svg-charts";
import * as shape from "d3-shape";
import {
  ScreenSizeContext,
  ScreenSizes,
} from "../../../contexts/ScreenSizeContext";
import { ThemeContext } from "../../../contexts/theme-context";
import GraphOverviewContainer from "./chartComponents/GraphOverviewContainer";
import PlotTopDecorator from "./PlotTopDecorator";
import CustomGrid from "./CustomGrid";
import GraphPanInfo from "./chartComponents/GraphPanInfo";
import {
  WeatherChartContext,
  WeatherContext,
} from "../../../contexts/weather-context";
import ChartContainer from "./chartComponents/ChartContainer";
import ChartAxis from "./chartComponents/ChartAxis";
import { ChartContext } from "../../../contexts/chart-context";
import ChartToolTip from "./chartComponents/ChartToolTip";
import {
  FormatDecoratorData,
  calculateGridValues,
  calculateSecondaryDataArray,
} from "./chartComponents/utils/chartFormatting";
import { CustomLine1, CustomLine2 } from "./chartComponents/CustomLines";
import ChartCurrentHourIndicator from "./chartComponents/ChartCurrentHourIndicator";

// Component declaration
export default function WeatherDetailsChart() {
  // Contexts
  const screenSize = useContext(ScreenSizeContext);
  const theme = useContext(ThemeContext);
  const weatherDetails = useContext(WeatherChartContext);
  const weather = useContext(WeatherContext);

  // State
  const [primaryConditionData, setPrimaryConditionData] = useState([]);
  const [secondaryConditionData, setSecondaryConditionData] = useState(
    Array(24).fill(null)
  );
  const [decoratorConditionData, setDecoratorConditionData] = useState([]);
  const [showDemo, setShowDemo] = useState(false);
  const [gridMax, setGridMax] = useState(25);
  const [gridMin, setGridMin] = useState(0);
  const [panPositionX, setPanPositionX] = useState(-1);

  // Calculate grid values and update state when weather details change
  useEffect(() => {
    const {
      primaryConditionDataType,
      decoratorConditionDataType,
      gridMax,
      gridMin,
    } = calculateGridValues(weatherDetails);

    setGridMax(gridMax);
    setGridMin(gridMin);

    setPrimaryConditionData(
      weather.timeArray[weatherDetails.selectedDay].map((time) => {
        return parseFloat(
          weather.weatherForecasts[weatherDetails.spotID].get(
            primaryConditionDataType
          ).forecast[time]
        );
      })
    );
    const decoratorDataArray = FormatDecoratorData(
      weather,
      weatherDetails,
      decoratorConditionDataType
    );
    setDecoratorConditionData(decoratorDataArray);
  }, [weatherDetails.forecastType.title, weatherDetails.selectedDay]);

  // Calculate secondary data array and update state when primary condition data changes
  useEffect(() => {
    const secondaryDataArray = calculateSecondaryDataArray(
      weather,
      weatherDetails,
      primaryConditionData
    );
    setSecondaryConditionData(secondaryDataArray);
  }, [primaryConditionData]);

  // Chart constants and calculations
  const referenceWidth = 750;
  const apx = (size = 0) => {
    let width = screenSize.size !== "xs" ? ScreenSizes["xs"] : screenSize.width;
    return (width / referenceWidth) * size;
  };

  const chartRelativeWidth = 0.9;
  const ChartCanvasWidth = apx(referenceWidth) * chartRelativeWidth;
  const ChartCanvasHeight = apx(750);
  const YAxisWidth = apx(40);
  const XAxisHeight = apx(30);
  const DecoratorContainerHeight = 30;
  const ChartTopInfoMargin = apx(60);
  const XAxisStartPosition = apx(0);
  const PlotAreaWidth = ChartCanvasWidth - YAxisWidth - XAxisStartPosition;
  const PlotAreaHeight =
    ChartCanvasHeight -
    XAxisHeight -
    DecoratorContainerHeight -
    ChartTopInfoMargin;
  const verticalContentInset = {
    top: DecoratorContainerHeight,
    bottom: apx(15),
  };
  const axisFontSize = apx(20);

  return (
    <ChartContext.Provider
      value={{
        ChartCanvasWidth,
        ChartCanvasHeight,
        DecoratorContainerHeight,
        PlotAreaWidth,
        PlotAreaHeight,
        verticalContentInset,
        YAxisWidth,
        XAxisHeight,
        axisFontSize,
        apx,
        primaryConditionData,
        gridMin,
        gridMax,
        XAxisStartPosition,
        dateList: weather.timeArray[0], // You might want to double-check this
        panPositionX,
        setPanPositionX,
      }}
    >
      <View
        style={{
          backgroundColor: theme.background.default,
          flexDirection: "column",
          justifyContent: "flex-start",
          width: ChartCanvasWidth,
          height: ChartCanvasHeight,
          zIndex: 1,
        }}
      >
        {panPositionX === -1 ? <GraphOverviewContainer /> : <GraphPanInfo />}
        <ChartContainer>
          <ChartAxis>
            {weatherDetails.forecastType.title === "Wind" && (
              <AreaChart
                style={{
                  flex: 1,
                }}
                data={secondaryConditionData}
                curve={shape.curveBumpX}
                contentInset={verticalContentInset}
                yMin={gridMin}
                yMax={gridMax}
                /*   numberOfTicks={5} */
                animate={false}
              >
                <CustomLine2 />
              </AreaChart>
            )}
            <AreaChart
              style={[StyleSheet.absoluteFill, {}]}
              data={primaryConditionData}
              curve={
                weatherDetails.forecastType.title === "Tide"
                  ? shape.curveBasis
                  : shape.curveBumpX
              }
              contentInset={verticalContentInset}
              yMin={gridMin}
              yMax={gridMax}
              numberOfTicks={5}
              animate={false}
            >
              <CustomGrid color={theme.divider} />

              <PlotTopDecorator
                color={theme.typography.secondary}
                _data={decoratorConditionData}
              />

              <CustomLine1 />
              <ChartCurrentHourIndicator />
              <ChartToolTip />
            </AreaChart>
          </ChartAxis>
        </ChartContainer>
      </View>
    </ChartContext.Provider>
  );
}
