import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ThemeContext } from "../../contexts/theme-context";
import FullScreenModal from "../../uiCommon/FullScreenModal";

const LeaveSessionModal = ({ handleCancel }) => {
  const navigation = useNavigation();
  const theme = useContext(ThemeContext);

  // Event handler for the "Go Back" button
  const handleGoBack = () => {
    handleCancel(false);
  };

  // Event handler for the "Continue" button
  const handleContinue = () => {
    navigation.popToTop();
    navigation.navigate("Profile");
  };

  return (
    <FullScreenModal
      message={
        <View style={styles.container}>
          {/* Message text */}
          <Text style={[styles.message, { color: theme.typography.primary }]}>
            Are you sure you would like to continue? All progress will be lost.
          </Text>
          <View style={styles.buttonContainer}>
            {/* "Go Back" button */}
            <TouchableOpacity
              style={[
                styles.button,
                {
                  backgroundColor: theme.background.default,
                  borderColor: theme.divider,
                },
              ]}
              onPress={handleGoBack}
            >
              <Text
                style={[styles.buttonText, { color: theme.typography.primary }]}
              >
                Go Back
              </Text>
            </TouchableOpacity>
            {/* "Continue" button */}
            <TouchableOpacity
              style={[
                styles.button,
                {
                  backgroundColor: theme.typography.alert,
                  borderColor: theme.divider,
                },
              ]}
              onPress={handleContinue}
            >
              <Text
                style={[styles.buttonText, { color: theme.typography.primary }]}
              >
                Continue
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      }
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: 10,
  },
  message: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
  },
  button: {
    marginHorizontal: 20,
    marginVertical: 10,
    borderRadius: 25,
    borderWidth: 2,
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default LeaveSessionModal;
