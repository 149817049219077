import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const WindIcon = (props) => (
  <Svg viewBox="0 0 100 100">
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      {...props}
    >
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M40 27a12 12 0 1 1 12 12H3m27 35a9 9 0 1 0 9-9H3.5"
      />
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M67 76c6.627 0 12-5.373 12-12s-5.373-12-12-12H1.5"
      />
      <Circle cx={50} cy={50} r={47.5} stroke={props.color} strokeWidth={5} />
    </Svg>
  </Svg>
);

export default WindIcon;
