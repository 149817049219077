import React, { useContext } from "react";
import { View, Text, TextInput, StyleSheet } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";

const ScreenNameInput = ({ screenName, setScreenName }) => {
  const theme = useContext(ThemeContext);

  return (
    <TextInput
      style={[
        styles.textInput,
        { color: theme.typography.primary, borderColor: theme.divider },
      ]}
      placeholder="Screen Name"
      value={screenName}
      onChangeText={(text) => setScreenName(text)}
    />
  );
};

const styles = StyleSheet.create({
  textInput: {
    fontSize: 16,
    borderWidth: 1,
    borderRadius: 2,
    paddingHorizontal: 15,
    paddingVertical: 10,
    height: 46,
  },
});

export default ScreenNameInput;
