import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const WaveIcon = (props) => (
  <Svg viewBox="0 0 100 100">
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      {...props}
    >
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M8 30s8.5 6.5 17 0c0 0 8.5-6 17 0 0 0 8.5 6 17 0 0 0 8.5-6 17 0s17 0 17 0M6.5 68.5l1.5.722s8.5 6.5 17 0c0 0 8.5-6 17 0 0 0 8.5 6 17 0 0 0 8.5-6 17 0s17 0 17 0h1M96.5 47.5 93 49.667s-8.5 6-17 0-17 0-17 0c-8.5 6-17 0-17 0-8.5-6-17 0-17 0-8.5 6.5-17 0-17 0L3.5 46.5"
      />
      <Circle cx={50} cy={50} r={47.5} stroke={props.color} strokeWidth={5} />
    </Svg>
  </Svg>
);

export default WaveIcon;
