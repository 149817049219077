import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
} from "react-native";
const dimensions = Dimensions.get("window");
// Themeing
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

const SoloButton = (props) => {
  const theme = useContext(ThemeContext);

  return (
    <TouchableOpacity
      style={[
        styles.button,
        {
          backgroundColor: theme.background.default,
          borderColor: theme.divider,
        },
      ]}
      onPressOut={props.submit}
    >
      <View style={styles.text_location}>
        <Text style={[styles.button_text, { color: theme.typography.primary }]}>
          {props.title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 100,
    alignSelf: "center",
  },
  button: {
    width: "90%",
    height: 50,
    borderRadius: 25,
    borderWidth: 2,
    position: "absolute",
    bottom: 20,
    left: "5%",
  },
  button_text: {
    fontSize: 16,
    fontWeight: "bold",
  },
  text_location: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default SoloButton;
