import { View, StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  MaterialCommunityIcons,
  FontAwesome5,
  Octicons,
} from "@expo/vector-icons";
import Home from "../home/Home";
import NewSession from "../sessionRecording/NewSession";
import Profile from "../profile/Profile";
import React from "react";
// Themeing
import { ThemeContext } from "../contexts/theme-context";
import { ScreenSizeContext, ScreenSizes } from "../contexts/ScreenSizeContext";
import { useContext, useState } from "react";
// Route
import { RouteContext } from "../contexts/route-context";

import Weather from "../weather/Weather";
import BottomTabBar from "./BottomTabBar";

function MainContainer() {
  // THEMEING
  const theme = useContext(ThemeContext);

  const screenSize = useContext(ScreenSizeContext);

  return (
    <View
      style={[
        styles.mainContainer,
        {
          minWidth: screenSize.size === "xs" ? 0 : ScreenSizes.xs,
          maxWidth: ScreenSizes.xs,
          borderColor: theme.divider,
          borderLeftWidth: screenSize.size === "xs" ? 0 : 1,
          borderRightWidth: screenSize.size === "xs" ? 0 : 1,
        },
      ]}
    >
      <TabNavigator />
    </View>
  );
}

const Tab = createBottomTabNavigator();

function TabNavigator() {
  const [tabNavState, setTabNavState] = useState(true);
  const [feedRerender, setFeedRerender] = useState(false);
  return (
    <>
      <RouteContext.Provider
        value={{ tabNavState, setTabNavState, feedRerender, setFeedRerender }}
      >
        <Tab.Navigator
          screenOptions={{ headerShown: false }}
          initialRouteName={"Home"}
          tabBar={(props) => <BottomTabBar {...props} />}
        >
          <Tab.Screen
            name="Profile"
            component={Profile}
            options={{
              tabBarLabel: "Profile",
              tabBarIcon: ({ color, size }) => (
                <FontAwesome5 name="user-circle" size={size} color={color} />
              ),
            }}
          />
          <Tab.Screen
            name="Home"
            component={Home}
            options={({}) => ({
              tabBarLabel: "Home",
              tabBarIcon: ({ color, size }) => (
                <Octicons name="home" size={size} color={color} />
              ),
            })}
          />
          <Tab.Screen
            name="Weather"
            component={Weather}
            options={{
              tabBarLabel: "Weather",
              tabBarIcon: ({ color, size }) => (
                <MaterialCommunityIcons
                  name="radar"
                  size={size}
                  color={color}
                />
              ),
            }}
          />
          <Tab.Screen name="New Session" component={NewSession} />
        </Tab.Navigator>
      </RouteContext.Provider>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 2,

    //outlineStyle: "solid",
    zIndex: 1,
  },
});

export default MainContainer;
