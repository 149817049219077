import { View, StyleSheet } from "react-native";
import ProfileAvatarUsername from "./ProfileAvatarUsername";
import ProfileHeaderStats from "./ProfileHeaderStats";

const ProfileHeader = ({ screenName, userID, postRemoved, setPostRemoved }) => {
  const USER_ALT = "Your Name";

  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        <ProfileAvatarUsername
          user={screenName ? screenName : USER_ALT}
          size={80}
        />
      </View>
      <ProfileHeaderStats
        userID={userID}
        postRemoved={postRemoved}
        setPostRemoved={setPostRemoved}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 30,
  },
  avatarContainer: {
    flex: 1,
  },
});

export default ProfileHeader;
