import { View, Text, StyleSheet } from "react-native";
// THEMEING
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

const Avatar = (props) => {
  const { user } = props;
  let { size } = props;

  if (typeof size === "undefined") {
    size = 40;
  }

  const theme = useContext(ThemeContext);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.av,
          {
            backgroundColor: theme.background.paper_e1,
            width: size,
            height: size,
            borderRadius: size / 2,
          },
        ]}
      >
        <Text
          style={[
            styles.text,
            { color: theme.typography.secondary, fontSize: size / 2 },
          ]}
        >
          {user[0]}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  av: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {},
});

export default Avatar;
