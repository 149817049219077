import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const CrowdIcon = (props) => (
  <Svg viewBox="0 0 100 100">
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      {...props}
    >
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M72 92V68c0-6-2.5-8-8-10.5S50 55 50 55s-8.5 0-14 2.5S28 62 28 68v24"
      />
      <Path
        fill={props.color}
        d="M21.5 60.237s-2.5-.532-7.5 0c-5 .531-8 2.125-8 2.125l3 12.02L20 85V67.675c0-3.72 1.5-7.438 1.5-7.438Zm57 0s2.5-.532 7.5 0 8 2.125 8 2.125l-3 12.02L80 85V67.676c0-3.72-1.5-7.44-1.5-7.44Z"
      />
      <Circle cx={50} cy={37} r={8.5} stroke={props.color} strokeWidth={5} />
      <Circle cx={82.5} cy={48.5} r={7.5} fill={props.color} />
      <Circle cx={17.5} cy={48.5} r={7.5} fill={props.color} />
      <Circle cx={50} cy={50} r={47.5} stroke={props.color} strokeWidth={5} />
    </Svg>
  </Svg>
);

export default CrowdIcon;
