import { G, Path } from "react-native-svg";
import { reverseCompassDirection } from "../../../utilFunctions/directionConversionFunctions";

function PlotTopDecorator({ x, y, _data, color }) {
  return _data.map((value, index) => {
    if ((index + 1) % 2 === 0 && index !== 0 && index !== 23)
      return (
        <G
          key={index}
          x={x(index) - 15}
          y={0}
          rotation={
            typeof value.val === "number"
              ? reverseCompassDirection(value.val)
              : 0
          }
          originX={15}
          originY={15}
          scale={(value.scale, value.scale)}
        >
          <Path
            fill={color ? color : "black"}
            d="M14.161 2.461c.297-.766 1.38-.766 1.678 0l8.075 20.798c.314.809-.586 1.55-1.32 1.087l-7.2-4.54a.9.9 0 0 0-.966.004l-7.002 4.497c-.733.47-1.64-.271-1.326-1.083L14.161 2.46Z"
          />
        </G>
      );
  });
}

export default PlotTopDecorator;
