export const getUserInfo = async (requestData) => {
  let requestParams = {};
  if (!requestData.projectionFields) {
    requestParams = { ...requestData, projectionFields: {} };
  } else {
    requestParams = requestData;
  }

  const searchParams = new URLSearchParams(
    JSON.stringify(requestParams)
  ).toString();
  const url =
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/UserInfo?" +
    searchParams;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

export const putUserInfo = async (requestData) => {
  // requestData = {userID: userID, settings:{settings:{update}}}
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  const url = new URL(
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/UserInfo"
  );
  let response = await fetch(url, requestOptions);
  response = await response.json();
  return response;
};

export const getUserStats = async (requestData) => {
  const searchParams = new URLSearchParams(
    JSON.stringify(requestData)
  ).toString();
  const url =
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/UserStats?" +
    searchParams;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};
