import { Text, StyleSheet, TouchableHighlight } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function PostTagBubble({ tag, onPress, selected }) {
  const theme = useContext(ThemeContext);

  return (
    <TouchableHighlight
      style={[
        styles.bubbleContainer,
        {
          borderColor: theme.typography.disabled,
          backgroundColor: selected ? theme.buttons.selected : "transparent",
        },
      ]}
      onPress={() => {
        onPress(tag);
      }}
      activeOpacity={0.9}
      underlayColor={theme.buttons.active}
    >
      <Text style={[styles.tagText, { color: theme.typography.secondary }]}>
        {selected ? "\u2713 " : ""}
        {tag}
      </Text>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  bubbleContainer: {
    height: 26,
    borderWidth: 1,
    borderRadius: 13,
    paddingVertical: 2,
    margin: 2,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 6,
  },
  tagText: {
    fontSize: 14,
    fontWeight: "400",
  },
});

export default PostTagBubble;
