import React, { useContext, useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import SoloButton from "../uiCommon/SoloButton";
import PageHeader from "../containers/PageHeader";
import LeaveSessionModal from "./sessionUIcomponents/LeaveSessionModal";
import NewSessionRatingInput from "./sessionUIcomponents/NewSessionRatingInput";
import { UserContext } from "../contexts/user-context";
import { postSessionInfo } from "../api/sessions";
import { putUserInfo } from "../api/user";
import { RouteContext } from "../contexts/route-context";

function NewSessionRating({ navigation, route }) {
  const theme = useContext(ThemeContext);
  const { user, setUser } = useContext(UserContext);
  const { setFeedRerender } = useContext(RouteContext);
  const [showExitModal, setShowExitModal] = useState(false);
  const [conditionFeedback, setConditionFeedback] = useState({
    waves: { rating: 3, tags: [] },
    wind: { rating: 3, tags: [] },
    tide: { rating: 3, tags: [] },
    crowd: { rating: 3, tags: [] },
  });

  // Function to update the feedback for a specific condition type and feedback type
  const alterFeedback = (conditionType, feedbackType, feedback) => {
    const nextConditionTypeFeedback = {
      ...conditionFeedback[conditionType],
      [feedbackType]: feedback,
    };
    setConditionFeedback({
      ...conditionFeedback,
      [conditionType]: nextConditionTypeFeedback,
    });
  };

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background.default,
          borderColor: theme.divider,
        },
      ]}
    >
      {showExitModal && <LeaveSessionModal handleCancel={setShowExitModal} />}
      <PageHeader
        header="New Session"
        subheader="Rate and Tag Session Conditions"
        leftButton={
          <AntDesign name="back" size={30} color={theme.typography.primary} />
        }
        leftPress={navigation.goBack}
        rightButton={
          <Ionicons
            name="ios-trash-outline"
            size={30}
            color={theme.typography.primary}
          />
        }
        rightPress={() => setShowExitModal(true)}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}
      >
        {/* Display input components for each condition type */}
        <NewSessionRatingInput
          conditionType="waves"
          feedbackObject={conditionFeedback["waves"]}
          alterFeedback={alterFeedback}
        />
        <NewSessionRatingInput
          conditionType="wind"
          feedbackObject={conditionFeedback["wind"]}
          alterFeedback={alterFeedback}
        />
        <NewSessionRatingInput
          conditionType="tide"
          feedbackObject={conditionFeedback["tide"]}
          alterFeedback={alterFeedback}
        />
        <NewSessionRatingInput
          conditionType="crowd"
          feedbackObject={conditionFeedback["crowd"]}
          alterFeedback={alterFeedback}
        />
        {/* Placeholder view to create space for buttons */}
        <View style={styles.buttonPlaceholder}></View>
      </ScrollView>
      <SoloButton
        submit={async () => {
          const response = await postSessionInfo({
            ...route.params,
            feedback: conditionFeedback,
            timeStamp: new Date().getTime(),
            userID: user.userID,
          });

          if (response) {
            console.log("Session post successful");
          } else {
            console.log("Oops, something went wrong");
          }
          setFeedRerender(true);
          navigation.popToTop();
          navigation.navigate("Profile");
        }}
        title="SUBMIT"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  scrollView: {
    width: "100%",
  },
  buttonPlaceholder: {
    height: 75,
  },
});

export default NewSessionRating;
