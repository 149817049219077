// Function to determine the time of day
export function getTimeOfDay(momentObj) {
  const hour = momentObj.hours();

  if (hour >= 5 && hour < 9) {
    return "Early Morning";
  } else if (hour >= 9 && hour < 12) {
    return "Late Morning";
  } else if (hour >= 12 && hour < 18) {
    return "Afternoon";
  } else if (hour >= 18 && hour < 21) {
    return "Evening";
  } else {
    return "Night";
  }
}

// Function to determine the season of the year
export function getSeason(momentObj) {
  const month = momentObj.month();

  if (month >= 2 && month <= 4) {
    return "Spring";
  } else if (month >= 5 && month <= 7) {
    return "Summer";
  } else if (month >= 8 && month <= 10) {
    return "Autumn";
  } else {
    return "Winter";
  }
}
