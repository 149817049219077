import { Text } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function PrivacyPolicy() {
  const theme = useContext(ThemeContext);
  return (
    <Text style={{ color: theme.typography.primary }}>
      PRIVACY POLICY
      {`\n\n`}
      Effective Date: July 25th, 2023
      {`\n\n`}
      Welcome to Swell! This privacy policy explains how we collect, use,
      disclose, and safeguard your personal information when you use our social
      media app and its services. By accessing or using our app, you consent to
      the practices described in this privacy policy. If you do not agree with
      this policy, please refrain from using our app.
      {`\n\n`}
      1. Information We Collect
      {`\n\n`}
      1.1. Account Information: When you create an account on our app, we may
      collect your username, email address, and other account-related
      information.
      {`\n\n`}
      1.2. User Content: We collect the content you post on our app, such as
      text, photos, videos, and other materials ("User Content").
      {`\n\n`}
      1.3. Usage Information: We may collect information about how you use our
      app, including your interactions with other users, the content you view,
      and the features you use.
      {`\n\n`}
      1.4. Device Information: We may collect information about your device,
      including the device type, operating system, unique device identifiers,
      and mobile network information.
      {`\n\n`}
      1.5. Location Information: If you grant us permission, we may collect your
      device's precise location to provide you with weather information based on
      your location.
      {`\n\n`}
      1.6. Cookies and Similar Technologies: We may use cookies and similar
      technologies to collect information about your interactions with our app
      and improve our services.
      {`\n\n`}
      2. How We Use Your Information
      {`\n\n`}
      2.1. Provide and Improve Services: We use your information to operate our
      app, provide you with the requested services, and enhance your user
      experience.
      {`\n\n`}
      2.2. Communication: We may use your email address to send you important
      updates, service notifications, and promotional materials. You can opt-out
      of marketing communications at any time.
      {`\n\n`}
      2.3. User Content: Your User Content may be displayed to other users of
      the app as per your privacy settings.
      {`\n\n`}
      2.4. Analytics: We use data analytics to analyze and understand how users
      interact with our app, allowing us to improve our services and features.
      {`\n\n`}
      2.5. Compliance: We may use your information to enforce our terms and
      conditions, as well as to comply with legal obligations and protect our
      rights and the rights of other users.
      {`\n\n`}
      3. How We Disclose Your Information
      {`\n\n`}
      3.1. User Content: Your User Content may be visible to other users based
      on your chosen privacy settings.
      {`\n\n`}
      3.2. Service Providers: We may share your information with third-party
      service providers who assist us in providing the app's services.
      {`\n\n`}
      3.3. Legal Requirements: We may disclose your information if required to
      do so by law or in response to valid requests from public authorities.
      {`\n\n`}
      3.4. Business Transfers: In the event of a merger, acquisition, or sale of
      assets, your information may be transferred to a new entity.
      {`\n\n`}
      4. Data Security
      {`\n\n`}
      4.1. We take reasonable measures to protect your information from
      unauthorized access, alteration, disclosure, or destruction.
      {`\n\n`}
      5. Your Choices
      {`\n\n`}
      5.1. Account Settings: You can access and update your account information
      through the app's settings.
      {`\n\n`}
      5.2. Marketing Communications: You can opt-out of receiving marketing
      communications from us by following the instructions in the emails we
      send.
      {`\n\n`}
      6. Children's Privacy
      {`\n\n`}
      6.1. Our app is not intended for children under the age of 13. If you are
      a parent or guardian and believe your child has provided personal
      information without your consent, please contact us at
      holddowndesigns@gmail.com, and we will promptly remove the information.
      {`\n\n`}
      7. Changes to this Privacy Policy
      {`\n\n`}
      7.1. We may update this privacy policy from time to time to reflect
      changes in our practices or for other operational, legal, or regulatory
      reasons. The revised policy will be effective when posted on our app.
      {`\n\n`}
      8. Contact Us
      {`\n\n`}
      8.1. If you have any questions or concerns about this privacy policy,
      please contact us at holddowndesigns@gmail.com.
    </Text>
  );
}

export default PrivacyPolicy;
