// function return all the tags associated with rating and +/- rating and all cat 6 tags

const waveTags = {
  1: ["Flat", "Chaotic", "Powerless"],
  2: ["Salty", "Untamed"],
  3: ["Gnarly"],
  4: ["Tubes"],
  5: ["Barrel"],
  6: ["Point", "Beach", "Sandbar", "Steep", "Rolling"],
};

const windTags = {
  1: ["Unruly"],
  2: ["Choppy"],
  3: ["Windy"],
  4: ["Light"],
  5: ["Calm Waters", "Glassy"],
  6: ["Onshore", "Offshore"],
};

const tideTags = {
  1: [],
  2: ["Close Outs", "Shallow", "Exposed"],
  3: [],
  4: [],
  5: ["Perfect"],
  6: ["Treadmill", "Reefs", "Rocks", "Sand", "Ebbing", "Flooding", "Slack"],
};

const crowdTags = {
  1: ["Packed", "Sardines", "Chaotic"],
  2: ["Tussling", "Paddle Battle", "Crowded"],
  3: ["Long Waits", "Systematic"],
  4: ["Company", "Sharing"],
  5: ["Solitude", "Alone", "Peaceful", "Private"],
  6: ["Friendly", "Good Vibes", "Cold"],
};

const tagGrab = (type, rating) => {
  let tagObject = {};
  let tags = [];
  if (type === "waves") tagObject = waveTags;
  if (type === "wind") tagObject = windTags;
  if (type === "tide") tagObject = tideTags;
  if (type === "crowd") tagObject = crowdTags;

  if (rating === 1)
    tags.push(...tagObject[rating], ...tagObject[rating + 1], ...tagObject[6]);
  else if (rating === 5)
    tags.push(...tagObject[rating], ...tagObject[rating - 1], ...tagObject[6]);
  else
    tags.push(
      ...tagObject[rating],
      ...tagObject[rating + 1],
      ...tagObject[rating - 1],
      ...tagObject[6]
    );
  return tags;
};

export default tagGrab;
