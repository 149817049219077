import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const TideIcon = (props) => (
  <Svg viewBox="0 0 100 100">
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      {...props}
    >
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M89.545 76.296c-4.88-8.025-6.25-26.297-6.25-26.297S81.045 20 72.045 20s-11.25 30-11.25 30-2.25 30-11.25 30-11.25-30-11.25-30-2.25-30-11.25-30-11.25 30-11.25 30S14.51 67.128 10 75.5"
      />
      <Path
        stroke={props.color}
        strokeDasharray="8 8"
        strokeWidth={5}
        d="M5 44.496h90"
      />
      <Circle cx={50} cy={50} r={47.5} stroke={props.color} strokeWidth={5} />
    </Svg>
  </Svg>
);

export default TideIcon;
