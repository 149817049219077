import { StyleSheet, View, Text } from "react-native";
import { AntDesign, Feather, Ionicons } from "@expo/vector-icons";
import IconButton from "../uiCommon/IconButton";
import { ThemeContext } from "../contexts/theme-context";
import { useContext, useEffect, useState } from "react";
import { deleteSession } from "../api/sessions";
import { RouteContext } from "../contexts/route-context";
import { UserContext } from "../contexts/user-context";
import { PostContext } from "../contexts/post-context";

function PostInteractionContainer({
  likes,
  expand,
  setExpand,
  postID,
  removePost,
}) {
  const [bookmarked, setBookmarked] = useState(false);
  const [liked, setLiked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const theme = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [usersPost, setUsersPost] = useState(false);
  const { userID } = useContext(PostContext);

  useEffect(() => {
    if (user) {
      if (user.userID === userID) {
        setUsersPost(true);
      }
    }
  }, [user]);

  return (
    <View style={[styles.interactionContainer]}>
      {usersPost && expand && (
        <View style={[styles.interactionComponent]}>
          <IconButton
            icon={
              <Ionicons
                name="ios-trash-outline"
                size={20}
                color={theme.typography.alert}
              />
            }
            onPress={() => {
              setConfirmDelete(true);
            }}
          />
        </View>
      )}
      {confirmDelete && expand && (
        <>
          <View style={[styles.interactionComponent]}>
            <IconButton
              icon={
                <Text style={{ color: theme.typography.alert, fontSize: 16 }}>
                  Yes
                </Text>
              }
              onPress={async () => {
                await deleteSession({ _id: postID });
                removePost(postID);
              }}
            />
          </View>
          <View style={[styles.interactionComponent]}>
            <IconButton
              icon={
                <Text style={{ color: theme.typography.primary, fontSize: 16 }}>
                  No
                </Text>
              }
              onPress={() => {
                setConfirmDelete(false);
              }}
            />
          </View>
        </>
      )}
      {/* <View style={[styles.interactionComponent]}>
        <View style={[styles.likeButtonContainer]}>
          <IconButton
            icon={
              <AntDesign
                name="like2"
                size={20}
                color={
                  liked ? theme.accentColors.theme : theme.typography.primary
                }
              />
            }
            onPress={() => {
              setLiked(!liked);
            }}
          />
          <Text
            style={[styles.likeCountText, { color: theme.typography.primary }]}
          >
            {likes}
          </Text>
        </View>
      </View>
      <View style={[styles.interactionComponent]}>
        <IconButton
          icon={
            <Feather
              name="bookmark"
              size={20}
              color={
                bookmarked ? theme.accentColors.gold : theme.typography.primary
              }
            />
          }
          onPress={() => {
            setBookmarked(!bookmarked);
          }}
        />
      </View> */}

      <View style={[styles.interactionComponent]}>
        <IconButton
          icon={
            <Feather
              name="more-vertical"
              size={20}
              color={theme.typography.primary}
            />
          }
          onPress={() => {
            setExpand(!expand);
            setConfirmDelete(false);
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  interactionContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  interactionComponent: { paddingLeft: 30 },
  likeButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  likeCountText: { fontSize: 16, paddingLeft: 6 },
});

export default PostInteractionContainer;
