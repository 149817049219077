import React, { useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import Animated, { FlipInXDown } from "react-native-reanimated";
import moment from "moment";

import { ThemeContext } from "../contexts/theme-context";
import { PostContext } from "../contexts/post-context";
import WeatherArrow from "../svgs/svgComponents/WeatherArrow";
import { reverseCompassDirection } from "../utilFunctions/directionConversionFunctions";
import {
  getTimeOfDay,
  getSeason,
} from "../utilFunctions/abstractTimeFunctions";

function PostConditionsContainer({ type }) {
  const theme = useContext(ThemeContext);
  const { weather, timing } = useContext(PostContext);

  const renderWaveConditions = () => (
    <View style={styles.conditionsTextContainer}>
      <Text
        style={[styles.conditionsText, { color: theme.typography.secondary }]}
      >
        {weather.wave.height.startTime}ft
      </Text>
      <View style={styles.conditionsTextContainer}>
        <WeatherArrow
          color={theme.accentColors.theme}
          rotation={reverseCompassDirection(weather.wave.direction.startTime)}
          chartWidth={18}
        />
      </View>
      <Text
        style={[styles.conditionsText, { color: theme.typography.secondary }]}
      >
        {weather.wave.period.startTime}s
      </Text>
    </View>
  );

  const renderWindConditions = () => (
    <View style={styles.conditionsTextContainer}>
      <Text
        style={[styles.conditionsText, { color: theme.typography.secondary }]}
      >
        {weather.wind.sustained.startTime}mph
      </Text>
      <View style={styles.conditionsTextContainer}>
        <WeatherArrow
          color={theme.accentColors.theme}
          rotation={reverseCompassDirection(weather.wind.direction.startTime)}
          chartWidth={18}
        />
      </View>
      {weather.wind.gust.startTime && (
        <>
          <Text
            style={[
              styles.conditionsText,
              { color: theme.typography.secondary },
            ]}
          >
            Gusts: {weather.wind.gust.startTime}mph
          </Text>
        </>
      )}
    </View>
  );

  const renderTideConditions = () => (
    <View style={styles.conditionsTextContainer}>
      <Text
        style={[styles.conditionsText, { color: theme.typography.secondary }]}
      >
        {weather.tide ? weather.tide.height.startTime + "ft" : "---"}
      </Text>
      <View style={styles.conditionsSeperatorTextContainer}>
        <Text
          style={[styles.conditionsText, { color: theme.typography.secondary }]}
        >
          {"\u00B7"}
        </Text>
      </View>
      <Text
        style={[styles.conditionsText, { color: theme.typography.secondary }]}
      >
        {weather.tide ? weather.tide.rate.startTime + "ft/hr" : "---"}
      </Text>
    </View>
  );

  const renderCrowdConditions = () => {
    const conditions = [
      { label: "ToD", value: getTimeOfDay(moment(timing.startTime)) },
      { label: "DoW", value: moment(timing.startTime).format("dddd") },
      { label: "season", value: getSeason(moment(timing.startTime)) },
    ];

    return conditions.map((item, idx, array) => (
      <View
        key={idx}
        style={[styles.conditionsTextContainer, { paddingRight: 0 }]}
      >
        <Text
          style={[styles.conditionsText, { color: theme.typography.secondary }]}
        >
          {item.value}
        </Text>
        {idx !== array.length - 1 && (
          <View style={styles.conditionsSeperatorTextContainer}>
            <Text
              style={[
                styles.conditionsText,
                { color: theme.typography.secondary },
              ]}
            >
              {"\u00B7"}
            </Text>
          </View>
        )}
      </View>
    ));
  };

  const renderConditionsComponent = () => {
    switch (type) {
      case "Wave":
        return renderWaveConditions();
      case "Wind":
        return renderWindConditions();
      case "Tide":
        return renderTideConditions();
      case "Crowd":
        return renderCrowdConditions();
      default:
        return null;
    }
  };

  return (
    <Animated.View style={styles.conditionsContainer} entering={FlipInXDown}>
      {renderConditionsComponent()}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  conditionsContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  conditionsTextContainer: {
    paddingRight: 5,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  conditionsSeperatorTextContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  conditionsText: {
    fontSize: 12,
    textAlign: "center",
    paddingRight: 5,
  },
});

export default PostConditionsContainer;
