import { replaceNullValues } from "../../../../../utilFunctions/weatherArrayFunctions";

export function calculateGridValues(weatherDetails) {
  let primaryConditionDataType, decoratorConditionDataType;
  let gridMax, gridMin;

  switch (weatherDetails.forecastType.title) {
    case "Wave":
      primaryConditionDataType = "swellHeight";
      decoratorConditionDataType = "swellDirection";
      gridMax = (Math.ceil(weatherDetails.weeklySwellExtremes.max / 5) + 1) * 5;
      gridMin = 0;
      break;
    case "Wind":
      primaryConditionDataType = "windSustained";
      decoratorConditionDataType = "windDirection";
      gridMax = (Math.ceil(weatherDetails.weeklyWindExtremes.max / 5) + 1) * 5;
      gridMin = 0;
      break;
    case "Tide":
      primaryConditionDataType = "tideHeight";
      decoratorConditionDataType = "tideRate";
      gridMax = weatherDetails.weeklyTideExtremes.max + 1;
      gridMin = weatherDetails.weeklyTideExtremes.min - 1;
      break;
    default:
      primaryConditionDataType = "swellHeight";
      decoratorConditionDataType = "swellDirection";
      gridMin = 0;
  }

  return {
    primaryConditionDataType,
    decoratorConditionDataType,
    gridMax,
    gridMin,
  };
}

export function calculateSecondaryDataArray(
  weather,
  weatherDetails,
  primaryConditionData
) {
  let secondaryDataArray;

  switch (weatherDetails.forecastType.title) {
    case "Wave":
      secondaryDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          return parseInt(
            weather.weatherForecasts[weatherDetails.spotID].get("swellPeriod")
              .forecast[time]
          );
        }
      );
      break;
    case "Wind":
      secondaryDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          return parseInt(
            weather.weatherForecasts[weatherDetails.spotID].get("windGust")
              .forecast[time]
          );
        }
      );
      secondaryDataArray = replaceNullValues(
        secondaryDataArray,
        primaryConditionData
      );
      break;
    case "Tide":
      secondaryDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          return parseInt(
            weather.weatherForecasts[weatherDetails.spotID].get("tideRate")
              .forecast[time]
          );
        }
      );
      break;
    default:
    // Handle other cases if needed
  }

  return secondaryDataArray;
}

export function FormatDecoratorData(
  weather,
  weatherDetails,
  decoratorConditionDataType
) {
  let decaratorDataArray;

  switch (weatherDetails.forecastType.title) {
    case "Wave":
      decaratorDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          return {
            val: parseFloat(
              weather.weatherForecasts[weatherDetails.spotID].get(
                decoratorConditionDataType
              ).forecast[time]
            ),
            scale: 0.9,
          };
        }
      );
      break;
    case "Wind":
      decaratorDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          return {
            val: parseFloat(
              weather.weatherForecasts[weatherDetails.spotID].get(
                decoratorConditionDataType
              ).forecast[time]
            ),
            scale: 0.9,
          };
        }
      );
      break;
    case "Tide":
      decaratorDataArray = weather.timeArray[weatherDetails.selectedDay].map(
        (time) => {
          const rate = parseFloat(
            weather.weatherForecasts[weatherDetails.spotID].get(
              decoratorConditionDataType
            ).forecast[time]
          );
          return {
            val: TideRateArrowDirection(rate),
            scale: TideRateArrowSize(rate),
          };
        }
      );
      break;
    default:
    // Handle other cases if needed
  }
  return decaratorDataArray;
}

const TideRateArrowDirection = (rate) => {
  if (rate >= 0) return 180;
  else return 0;
};

/**
 * Maps a value from the range [0, 2] to the range [0.5, 0.9]
 * with a linear scale applied.
 * @param {number} value - The input value to be mapped.
 * @returns {number} - The mapped value.
 */
function TideRateArrowSize(value) {
  if (value <= 0) {
    return 0.5; // Value less than or equal to 0 is mapped to 0.5
  } else if (value >= 2) {
    return 0.9; // Value greater than or equal to 2 is mapped to 0.9
  } else {
    return 0.5 + (value / 2) * 0.4; // Linear mapping between 0 and 2
  }
}
