export function shortenUnit(unit) {
  const units = {
    "miles per hour": "mph",
    "kilometers per hour": "km/h",
    seconds: "s",
    meters: "m",
    feet: "'",
    ft: "'",
    "feet/hour": "ft/hr",
  };

  return units[unit] || unit;
}
