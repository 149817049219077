import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const LogoCompressed = (props) => (
  <Svg viewBox="0 0 100 100">
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      {...props}
    >
      <Circle cx={50} cy={50} r={47.5} stroke={props.color} strokeWidth={5} />
      <Path
        stroke={props.color}
        strokeWidth={5}
        d="M70 18c-24 0 0 64-24 64m9-64c-24 0 0 64-24 64m54-64c-24 0 0 64-24 64H16c24 0 0-64 24-64h45Z"
      />
    </Svg>
  </Svg>
);

export default LogoCompressed;
