import { useContext, useMemo } from "react";
import { StyleSheet, View, Text } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import DateSelectorButton from "./DateSelectorButton";
import moment from "moment";

function DateSelector({
  selectedDate,
  setSelectedDate,
  inverted = false,
  dayCnt = 7,
  alignSelf = "center",
}) {
  const theme = useContext(ThemeContext);

  const numDays = useMemo(() => Array(dayCnt).fill(0), []); // Memoize the array initialization

  const formattedDate = useMemo(
    () =>
      inverted
        ? moment().subtract(selectedDate, "days").format("dddd, MMMM Do, YYYY")
        : moment().add(selectedDate, "days").format("dddd, MMMM Do, YYYY"), // Memoize the date formatting
    [selectedDate]
  );

  return (
    <View style={[styles.contentContainer, { alignSelf: alignSelf }]}>
      <View
        style={[
          styles.buttonContainer,
          { flexDirection: inverted ? "row-reverse" : "row" },
        ]}
      >
        {numDays.map((_, idx) => {
          return (
            <DateSelectorButton
              key={idx}
              selected={selectedDate}
              index={idx}
              onSelect={setSelectedDate}
              inverted={inverted}
            />
          );
        })}
      </View>
      <View style={[styles.displayContainer]}>
        <Text
          style={[styles.displayText, { color: theme.typography.secondary }]}
        >
          {formattedDate}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "95%",
    paddingVertical: 20,
  },
  buttonContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  displayContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  displayText: { fontSize: 16 },
});

export default DateSelector;
