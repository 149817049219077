// API to post session data
export const postSessionInfo = async (requestData) => {
  // requestData = {userID: userID, settings:{settings:{update}}}
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  const url = new URL(
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/SessionInfo"
  );
  let response = await fetch(url, requestOptions);
  response = await response.json();
  return response;
};

// API to get recent session posts
export const getSessions = async (requestData) => {
  let requestParams = {};
  if (!requestData.projectionFields) {
    requestParams = { ...requestData, projectionFields: {} };
  } else {
    requestParams = requestData;
  }

  const searchParams = new URLSearchParams(
    JSON.stringify(requestParams)
  ).toString();
  const url =
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/SessionInfo?" +
    searchParams;
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

// API to delete session from db
// requestData = {_id: sessionDocID}
export const deleteSession = async (requestData) => {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  };
  const url = new URL(
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/SessionInfo"
  );
  let response = await fetch(url, requestOptions);
  response = await response.json();
  return response;
};
