import { Text } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function TermsAndConditions() {
  const theme = useContext(ThemeContext);
  return (
    <Text style={{ color: theme.typography.primary }}>
      TERMS AND CONDITIONS
      {`\n\n`}
      Effective Date: July 25th, 2023
      {`\n\n`}
      Welcome to Swell! These terms and conditions govern your use of our social
      media app and its services. By accessing or using our app, you agree to
      comply with and be bound by the following terms and conditions. If you do
      not agree with these terms, please refrain from using our app.
      {`\n\n`}1. User Accounts
      {`\n\n`}
      1.1. You must be at least 13 years old to create an account and use our
      app. If you are below the age of 18, you confirm that you have obtained
      permission from your parent or legal guardian to use our app.
      {`\n\n`}
      1.2. You are responsible for maintaining the confidentiality of your
      account credentials, including your username and password. You are solely
      responsible for any activity that occurs under your account.
      {`\n\n`}
      1.3. You agree to provide accurate, complete, and up-to-date information
      when creating your account. You also agree not to create an account using
      someone else's identity or false information.
      {`\n\n`}2. User Content
      {`\n\n`}
      2.1. By posting content on our app, including text, photos, videos, and
      other materials ("User Content"), you grant us a non-exclusive, worldwide,
      royalty-free, and transferable license to use, reproduce, modify, adapt,
      distribute, and display your User Content for the purposes of operating
      and improving our app.
      {`\n\n`}
      2.2. You are solely responsible for the User Content you post and the
      consequences of sharing such content. You affirm that you have the
      necessary rights to post the User Content and that it does not violate any
      laws or infringe upon any third-party rights.
      {`\n\n`}
      2.3. We reserve the right to remove any User Content that we deem
      inappropriate, offensive, or in violation of these terms and conditions,
      without prior notice.
      {`\n\n`}3. App Usage and Conduct
      {`\n\n`}
      3.1. You agree to use our app only for lawful purposes and in compliance
      with all applicable laws and regulations.
      {`\n\n`}
      3.2. You will not use our app to:
      {`\n\n`}
      a. Post or distribute any content that is unlawful, harmful, defamatory,
      obscene, or otherwise objectionable.
      {`\n\n`}
      b. Impersonate any person or entity or falsely represent your affiliation
      with any person or entity.
      {`\n\n`}
      c. Engage in any activity that could disrupt or interfere with the
      functioning of our app or compromise the security of other users'
      accounts.
      {`\n\n`}
      d. Collect or harvest any information about other users without their
      consent.
      {`\n\n`}
      e. Violate the privacy rights or intellectual property rights of others.
      {`\n\n`}4. Weather Information
      {`\n\n`}
      4.1. Our app provides weather information for general informational
      purposes only. We make no warranties or representations regarding the
      accuracy, reliability, or completeness of the weather information
      displayed in our app.
      {`\n\n`}
      4.2. You understand and acknowledge that weather conditions can be
      unpredictable and may vary from the information displayed in our app. We
      do not assume any liability for any damages or losses incurred as a result
      of relying on the weather information provided in our app.
      {`\n\n`}5. Intellectual Property
      {`\n\n`}
      5.1. All intellectual property rights in our app and its content,
      including but not limited to text, graphics, logos, and software, are
      owned by or licensed to us. You may not reproduce, modify, distribute, or
      otherwise use our app's content without our prior written consent.
      {`\n\n`}6. Termination
      {`\n\n`}
      6.1. We reserve the right to terminate or suspend your account, or
      restrict your access to our app, at any time and for any reason, without
      prior notice or liability.
      {`\n\n`}7. Disclaimer of Warranties
      {`\n\n`}
      7.1. Our app is provided on an "as is" and "as available" basis. We make
      no warranties or representations of any kind, express or implied,
      regarding the operation or availability of our app or the accuracy,
      reliability, or completeness of any information displayed in our app.
      {`\n\n`}8. Limitation of Liability
      {`\n\n`}
      8.1. To the maximum extent permitted by applicable law, we shall not be
      liable for any indirect, incidental, special, consequential, or punitive
      damages arising out of or in connection with your use of our app, even if
      we have been advised of the possibility of such damages.
      {`\n\n`}9. Governing Law and Jurisdiction
      {`\n\n`}
      9.1. These terms and conditions shall be governed by and construed in
      accordance with the laws of United States. Any dispute arising out of or
      in connection with these terms and conditions shall be subject to the
      exclusive jurisdiction of the courts of United States.
    </Text>
  );
}

export default TermsAndConditions;
