import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import Svg, { Circle, Path, Text } from "react-native-svg";

function CompassLogoTextCenter({
  color,
  text,
  textColor,
  rotation,
  chartWidth,
}) {
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    if (typeof rotation !== undefined) {
      setRotate(rotation);
    }
  }, []);

  return (
    <View
      style={[
        styles.container,
        {
          transform: [{ rotate: `${rotation}deg` }],
        },
      ]}
    >
      <Svg
        style={[styles.svg, { width: chartWidth ? chartWidth : 45 }]}
        viewBox="0 0 180 180"
      >
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={180}
          height={180}
          fill="none"
          fontFamily="sans-serif"
        >
          <Circle cx={90} cy={90} r={47} stroke={color} strokeWidth={4} />
          <Path
            fill={color}
            d="M81.34 5c3.849-6.667 13.471-6.667 17.32 0l34.641 60L113 48l-23-5.5L67 48 46.699 65l34.64-60Z"
          />
          <Circle cx={90} cy={90} r={89.5} />
          {text && (
            <Text
              x="90"
              y="110"
              textAnchor="middle"
              fontWeight="bold"
              fontSize="54"
              fill={textColor}
              transform={`rotate(${-rotation}, 90, 90)`}
            >
              {text}
            </Text>
          )}
        </Svg>
      </Svg>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  svg: {
    width: 40,
    aspectRatio: 1,
  },
});

export default CompassLogoTextCenter;
