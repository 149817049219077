// Import necessary dependencies and components
import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import SoloButton from "../uiCommon/SoloButton";
import PageHeader from "../containers/PageHeader";
import SingleSelectDropdown from "../uiCommon/SingleSelectDropDown";
import HelperText from "../uiCommon/HelperText";
import LeaveSessionModal from "./sessionUIcomponents/LeaveSessionModal";
import AuthStack from "../authorization/AuthStack";

import { ThemeContext } from "../contexts/theme-context";
import { getPOIInfo } from "../api/poi";

const NewSessionLocation = ({ navigation }) => {
  // Get the theme from the context
  const theme = useContext(ThemeContext);

  // Define state variables
  const [alertText, setAlertText] = useState(" ");
  const [showExitModal, setShowExitModal] = useState(false);
  const [surfSpot, setSurfSpot] = useState(null);
  const [activeSurfSpots, setActiveSurfSpots] = useState([
    { name: "No Surf Spots Available...", spotID: 1 },
  ]);
  const region = "Pacific Northwest";

  // Fetch active surf spots on component mount
  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        queryTerms: {
          "location.region": "Pacific Northwest",
          "type.surfSpot": true,
        },
        projectionFields: {
          name: true,
          spotID: true,
          _id: false,
        },
      };
      const data = await getPOIInfo(requestData);
      setActiveSurfSpots(data);
    };
    fetchData();
  }, []);

  // Render the component
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background.default,
          borderColor: theme.divider,
        },
      ]}
    >
      <AuthStack />
      {showExitModal && <LeaveSessionModal handleCancel={setShowExitModal} />}
      {/* Render the page header */}
      <PageHeader
        header="New Session"
        subheader="Select Location"
        leftButton={
          <AntDesign name="back" size={30} color={theme.typography.primary} />
        }
        leftPress={navigation.goBack}
        rightButton={
          <Ionicons
            name="ios-trash-outline"
            size={30}
            color={theme.typography.primary}
          />
        }
        rightPress={() => {
          setShowExitModal(true);
        }}
      />
      {/* Render the region dropdown */}
      <SingleSelectDropdown prompt={region} isActive={false} />
      <View style={{ paddingTop: 10 }}></View>
      {/* Render the surf spot dropdown */}
      <SingleSelectDropdown
        options={activeSurfSpots.map((option) => ({
          item: option.name,
          key: option.spotID,
        }))}
        onSelect={(selection) => {
          setSurfSpot(selection);
          setAlertText("");
        }}
        selected={surfSpot}
        prompt="Surf Spot"
      />
      {/* Render the alert text */}
      <HelperText text={alertText} alert />
      {/* Render the continue button */}
      <SoloButton
        submit={() => {
          if (surfSpot === null) {
            setAlertText("Select Surf Spot");
          } else {
            navigation.navigate("Session Timing", {
              location: { region: region, surfSpot: surfSpot },
            });
          }
        }}
        title="CONTINUE"
      />
    </View>
  );
};

// Define the component's styles
const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    paddingHorizontal: "2.5%",
  },
});

export default NewSessionLocation;
