import { useContext } from "react";
import { Line, G } from "react-native-svg";
import { ChartContext } from "../../../contexts/chart-context";

export default function CustomGrid({ x, y, ticks, data, color }) {
  const { gridMax, gridMin } = useContext(ChartContext);
  if (!ticks.includes(gridMax)) {
    ticks.push(gridMax);
  }
  if (!ticks.includes(gridMin)) {
    ticks.unshift(gridMin);
  }

  return (
    <G>
      {
        // Horizontal grid
        ticks.map((tick) => (
          <Line
            key={tick}
            x1="0%"
            x2="100%"
            y1={y(tick)}
            y2={y(tick)}
            stroke={color ? color : "black"}
          />
        ))
      }
      {
        // Vertical grid 24 chart custom logic
        data.map((_, index) => {
          if (index % 6 === 0 && data.length - 1 !== index && index !== 0) {
            return (
              <Line
                key={index.toString()}
                y1={y(ticks[ticks.length - 1])}
                y2={y(gridMin)}
                x1={x(index)}
                x2={x(index)}
                stroke={color ? color : "black"}
                strokeDasharray={[6, 3]}
              />
            );
          }
        })
      }
    </G>
  );
}
