import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import SliderChart from "../../svgs/svgComponents/SliderChart";
import { ThemeContext } from "../../contexts/theme-context";
import { ScreenSizeContext } from "../../contexts/ScreenSizeContext";
import { useContext } from "react";
import CompassLogoEmptyCenter from "../../svgs/svgComponents/CompassArrowTextCenter";
import {
  WeatherContext,
  WeatherChartContext,
} from "../../contexts/weather-context";
import moment from "moment";
import { findMinMaxValues } from "../../utilFunctions/maxMinValueFunctions";
import { reverseCompassDirection } from "../../utilFunctions/directionConversionFunctions";
import { shortenUnit } from "../../utilFunctions/unitConversionFunctions";

function WeeklyWeatherOverview({ id }) {
  const theme = useContext(ThemeContext);
  const screenSize = useContext(ScreenSizeContext);
  const weather = useContext(WeatherContext);
  const chart = useContext(WeatherChartContext);

  const spotWeather = weather.weatherForecasts[chart.spotID];

  const maxSwell = findMinMaxValues(
    weather.timeArray[id],
    spotWeather.get("swellHeight").forecast
  ).max;
  const minMaxWind = {
    min: chart.dailySustainedExtremes[id].min,
    max: chart.dailyGustExtremes[id].max.value
      ? chart.dailyGustExtremes[id].max
      : chart.dailySustainedExtremes[id].max,
  };

  return (
    <TouchableOpacity
      style={[styles.contentContainer, { borderBottomColor: theme.divider }]}
      onPress={() => {
        chart.setSelectedDay(id);
      }}
      activeOpacity={0.7}
    >
      <View style={[styles.dateContainer]}>
        <Text style={[styles.dateText, { color: theme.typography.primary }]}>
          {id ? moment(weather.timeArray[id][0]).format("ddd") : "Today"}
        </Text>
      </View>
      <View style={[styles.waveContainer]}>
        <CompassLogoEmptyCenter
          color={theme.accentColors.theme}
          text={
            maxSwell.value + shortenUnit(spotWeather.get("swellHeight").units)
          }
          textColor={theme.typography.primary}
          rotation={reverseCompassDirection(
            spotWeather.get("swellDirection").forecast[maxSwell.key]
          )}
        />
        <Text style={[styles.conditionsTextContainer]}>
          <Text
            style={[
              styles.conditionsText,
              { color: theme.typography.secondary },
            ]}
          >
            {spotWeather.get("swellPeriod").forecast[maxSwell.key]}
          </Text>
          <Text
            style={[
              styles.conditionsTextLabel,
              { color: theme.typography.secondary },
            ]}
          >
            {shortenUnit(spotWeather.get("swellPeriod").units)}
          </Text>
        </Text>
      </View>
      <View style={[styles.windContainer]}>
        <Text style={[styles.conditionsTextContainer]}>
          <Text
            style={[
              styles.conditionsText,
              { color: theme.typography.secondary },
            ]}
          >
            {minMaxWind.min.value}
          </Text>
          <Text
            style={[
              styles.conditionsTextLabel,
              { color: theme.typography.secondary },
            ]}
          >
            {shortenUnit(spotWeather.get("windSustained").units)}
          </Text>
        </Text>
        <SliderChart
          chartWidth={
            screenSize.size !== "xs" ? 140 : Math.floor(screenSize.width / 4)
          }
          minRot={reverseCompassDirection(
            spotWeather.get("windDirection").forecast[minMaxWind.min.key]
          )}
          maxRot={reverseCompassDirection(
            spotWeather.get("windDirection").forecast[minMaxWind.max.key]
          )}
          localMin={minMaxWind.min.value}
          localMax={minMaxWind.max.value}
          absoluteMin={chart.weeklyWindExtremes.min}
          absoluteMax={chart.weeklyWindExtremes.max}
        />
        <Text style={[styles.conditionsTextContainer]}>
          <Text
            style={[styles.conditionsText, { color: theme.typography.primary }]}
          >
            {minMaxWind.max.value}
          </Text>
          <Text
            style={[
              styles.conditionsTextLabel,
              { color: theme.typography.primary },
            ]}
          >
            {shortenUnit(spotWeather.get("windSustained").units)}
          </Text>
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
    alignItem: "center",
    height: 60,
    borderBottomWidth: 1,
    marginHorizontal: 10,
  },
  dateContainer: {
    flex: 2,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  dateText: { fontSize: 16 },
  waveContainer: {
    flex: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  windContainer: {
    flex: 6,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  conditionsTextContainer: { marginHorizontal: 0 },
  conditionsText: { fontSize: 16 },
  conditionsTextLabel: { fontSize: 10 },
});

export default WeeklyWeatherOverview;
