import { useState, useEffect, useContext } from "react";
import SingleSelectDropdown from "../../uiCommon/SingleSelectDropDown";
import { getPOIInfo } from "../../api/poi";
import { WeatherContext } from "../../contexts/weather-context";
import { View } from "react-native";

function LocationSearch({ navigation }) {
  const weather = useContext(WeatherContext);
  const [activeRegions, setActiveRegions] = useState([
    { name: "Pacific Northwest", spotID: 0 },
  ]);
  const [region, setRegion] = useState(null);
  const [activeSurfSpots, setActiveSurfSports] = useState([
    { name: "Select a region", spotID: 0 },
  ]);
  const [surfSpot, setSurfSpot] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        queryTerms: {
          "location.region": region.item,
          "type.surfSpot": true,
        },
        projectionFields: {
          name: true,
          spotID: true,
          _id: false,
        },
      };
      const data = await getPOIInfo(requestData);
      setActiveSurfSports(data);
    };
    if (region) {
      setActiveSurfSports([{ name: "Loading surf spots...", spotID: 0 }]);
      fetchData();
    }
  }, [region]);

  return (
    <>
      {/* Will need to create API for return unique regions in order to set active*/}
      <SingleSelectDropdown
        options={activeRegions.map((option) => ({
          item: option.name,
          key: option.spotID,
        }))}
        onSelect={(selection) => {
          setRegion(selection);
        }}
        selected={region}
        prompt="Select Region"
      />
      <View style={{ paddingTop: 10 }}></View>
      <SingleSelectDropdown
        options={activeSurfSpots.map((option) => ({
          item: option.name,
          key: option.spotID,
        }))}
        onSelect={(selection) => {
          if (
            selection.item !== "Select a region" &&
            selection.item !== "Loading surf spots..."
          ) {
            setSurfSpot(selection);
            if (!weather.weatherSpots.includes(selection.key)) {
              weather.setWeatherSpots([...weather.weatherSpots, selection.key]);
            }

            navigation.navigate("SpotDetails", {
              spotID: selection.key,
              name: selection.item,
              region: region.item,
              saved: weather.weatherSpots.includes(selection.key)
                ? true
                : false,
            });
          }
        }}
        selected={surfSpot}
        prompt="Surf Spot"
      />
    </>
  );
}

export default LocationSearch;
