import { StyleSheet, View, Text, TouchableHighlight } from "react-native";
// Themeing
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";

function NavigationHeader({ paths, activePath, setActivePath }) {
  const theme = useContext(ThemeContext);
  const navOptions = paths.map((path, idx) => {
    return (
      <NavLink
        key={idx}
        path={path}
        activePath={activePath}
        setActivePath={setActivePath}
      />
    );
  });
  return (
    <View style={[styles.navContainer, { borderColor: theme.divider }]}>
      {navOptions}
    </View>
  );
}

function NavLink({ path, activePath, setActivePath }) {
  const theme = useContext(ThemeContext);

  return (
    <TouchableHighlight
      activeOpacity={0.5}
      underlayColor={theme.background.paper_e2}
      onPress={() => {
        setActivePath(path);
      }}
      style={[
        styles.link,
        {
          borderBottomWidth: path === activePath ? 2 : 0,
          borderColor: theme.accentColors.theme,
        },
      ]}
    >
      <Text style={[styles.linkText, { color: theme.typography.secondary }]}>
        {path}
      </Text>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  navContainer: {
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    height: 36,
  },
  link: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: 10,
    paddingBottom: 2,
  },
  linkText: { fontSize: 14, flex: 1 },
});

export default NavigationHeader;
