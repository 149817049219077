export const getPOIInfo = async (requestData) => {
  let requestParams = {};
  if (!requestData.projectionFields) {
    requestParams = { ...requestData, projectionFields: {} };
  } else {
    requestParams = requestData;
  }

  const searchParams = new URLSearchParams(
    JSON.stringify(requestParams)
  ).toString();
  const url =
    "https://0rucnzu9tf.execute-api.us-west-2.amazonaws.com/Prod/POIInfo?" +
    searchParams;
  const response = await fetch(url);
  return await response.json();
};
