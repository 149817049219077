import React, { useState } from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";

const Checkbox = ({ label, isChecked, onToggle }) => {
  const [checked, setChecked] = useState(isChecked || false);

  const handleToggle = () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);
    onToggle && onToggle(newCheckedState);
  };

  return (
    <TouchableOpacity onPress={handleToggle}>
      <View style={styles.container}>
        <View style={[styles.checkbox, checked && styles.checkboxChecked]}>
          {checked && <Text style={styles.checkmark}>✓</Text>}
        </View>
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderColor: "#000",
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  checkboxChecked: {
    backgroundColor: "#000",
  },
  checkmark: {
    color: "#fff",
  },
  label: {
    fontSize: 16,
  },
});

export default Checkbox;
