import { StyleSheet, View, Text } from "react-native";
import { ThemeContext } from "../../contexts/theme-context";
import { useContext } from "react";
import WeeklyWeatherOverview from "./WeeklyWeatherOverview";
import { WeatherChartContext } from "../../contexts/weather-context";

function WeeklyWeatherContainer() {
  const theme = useContext(ThemeContext);
  const chart = useContext(WeatherChartContext);

  return (
    <View style={[styles.contentContainer, { borderColor: theme.divider }]}>
      <View style={[styles.titleContainer, { borderColor: theme.divider }]}>
        <Text style={[styles.titleText, { color: theme.typography.primary }]}>
          Extended Forecast
        </Text>
      </View>
      {Array(6)
        .fill(0)
        .map((_, idx) => (
          <WeeklyWeatherOverview key={idx} id={idx} />
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "column",
    width: "90%",
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 20,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  titleText: { fontSize: 16, paddingTop: 10, paddingBottom: 10 },
});

export default WeeklyWeatherContainer;
