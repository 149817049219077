import { View, Text, StyleSheet, Modal } from "react-native";
import { ThemeContext } from "../contexts/theme-context";
import { useContext } from "react";
import Animated, {
  FadeIn,
  FadeOut,
  SlideInDown,
  SlideOutDown,
} from "react-native-reanimated";

function FullScreenModal({ message, width = "60%", height = "auto" }) {
  const theme = useContext(ThemeContext);
  return (
    <Animated.View
      style={[
        styles.alertContainer,
        { backgroundColor: theme.background.default_blur },
      ]}
      entering={FadeIn}
      exiting={FadeOut}
    >
      <Animated.View
        style={[
          styles.modalContainer,
          {
            backgroundColor: theme.background.paper_e2,
            borderColor: theme.divider,
            width: width,
            height: height,
          },
        ]}
        entering={SlideInDown}
        exiting={SlideOutDown}
      >
        {message}
      </Animated.View>
    </Animated.View>
  );
}
const styles = StyleSheet.create({
  alertContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    borderRadius: 20,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default FullScreenModal;
