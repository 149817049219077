import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ProfileMain from "./ProfileMain";
import ProfileSettings from "./ProfileSettings";

const Stack = createNativeStackNavigator();

function Profile() {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={"UserProfile"}
    >
      <Stack.Screen name="UserProfile" component={ProfileMain} />
      <Stack.Screen name="ProfileSettings" component={ProfileSettings} />
    </Stack.Navigator>
  );
}

export default Profile;
